import { useState } from 'react';
import { orderBy } from 'lodash';
import useStateFromProps from '../StateFromProps/StateFromProps';

const useSorting = (data) => {
  const [sortedData, setSortedData] = useStateFromProps(data, true);
  const [sortedBy, setSortedBy] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const handleSort = clickedColumn => () => {
    let newOrder = sortDirection === 'asc' ? 'desc' : 'asc';
    if (sortedBy !== clickedColumn) {
      newOrder = 'asc';
    }
    setSortedBy(clickedColumn);
    setSortDirection(newOrder);
    setSortedData(d => orderBy(d, [clickedColumn], [newOrder]));
  };
  const reset = () => {
    setSortedBy(null);
    setSortDirection(null);
  };
  return {
    sortedData, sortedBy, sortDirection, handleSort, reset
  };
};

export default useSorting;
