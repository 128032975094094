import React, { useEffect } from 'react';
import axios from 'axios';
import { SemanticToastContainer } from 'react-semantic-toasts';
import Router from './AppRouter';
import useToast from './hooks/Toast/Toast';
import './App.css';
import './i18n';

const App = () => {
  const sendToast = useToast();

  useEffect(() => {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      () => sendToast('error')
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ overflow: 'hidden', height: '100vh' }}>
      <Router />
      <SemanticToastContainer position='bottom-left' />
    </div>
  );
};

export default App;
