import React, { useState, useEffect } from 'react';
import { Modal, Button, Checkbox, Divider, Header } from 'semantic-ui-react';
import { isArray, isBoolean, isObject, get } from 'lodash';
import { useTranslation } from 'react-i18next';

const PlaceholderModal = ({
  documentJson,
  showModal,
  setShowModal,
  changeTextFunc,
  currentValue = '',
  setProp,
  ifConditionalTrueHideElement
}) => {
  const [checkedField, setCheckedField] = useState();
  const [checkBehavior, setCheckBehavior] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setCheckedField(currentValue);
    setCheckBehavior(ifConditionalTrueHideElement);
  }, [showModal, currentValue]);

  const checkDocType = (providedDocument, propKey, modalShowState) => {
    let currentField = get(providedDocument, propKey);

    if (isArray(currentField) && propKey === modalShowState.listSource) {
      return getDocumentFields(currentField[0], {
        ...modalShowState,
        parent: '',
        showRootFields: true
      });
    } else if (
      isObject(currentField) &&
      !isArray(currentField) &&
      modalShowState.showRootFields
    ) {
      let validPropKey = isNaN(propKey) ? propKey : `[${propKey}]`;

      let addParent = isNaN(propKey)
        ? modalShowState.parent + '.'
        : modalShowState.parent;

      return getDocumentFields(currentField, {
        ...modalShowState,
        parent: `${modalShowState.parent ? addParent : ''}${validPropKey}`,
        showRootFields: true
      });
    } else if (
      isArray(currentField) &&
      modalShowState.parent !== '' &&
      modalShowState.showRootFields
    ) {
      return getDocumentFields(currentField, {
        ...modalShowState,
        parent: `${
          modalShowState.parent ? modalShowState.parent + '.' : ''
        }${propKey}`,
        showRootFields: true
      });
    } else if (isBoolean(currentField)) {
      let thisFieldName =
        modalShowState.listSource && modalShowState.parent
          ? 'item.' + `${modalShowState.parent}.` + propKey // eslint-disable-line
          : modalShowState.listSource
          ? 'item.' + propKey // eslint-disable-line
          : modalShowState.parent
          ? modalShowState.parent + '.' + propKey // eslint-disable-line
          : propKey; // eslint-disable-line

      return (
        <Checkbox
          label={propKey}
          name={thisFieldName}
          parent={modalShowState.parent}
          checked={checkedField === thisFieldName}
          onClick={changeSelected}
        />
      );
    } else return <div />;
  };

  const changeSelected = (_, data) => {
    let newValue = data.name === checkedField ? '' : data.name;
    setCheckedField(newValue);
  };

  const onOkClicked = () => {
    changeTextFunc(checkedField);
    setShowModal({
      show: false,
      listSource: '',
      parent: ''
    });
  };

  const onCancel = () => {
    setCheckedField(currentValue);
    setShowModal({
      show: false,
      listSource: '',
      parent: ''
    });
  };

  const getDocumentFields = (providedDocument = {}, modalShowState) => {
    let objKeys = Object.keys(providedDocument);
    if (showModal.show) {
      if (
        !objKeys.includes(modalShowState.listSource) &&
        !modalShowState.showRootFields
      ) {
        return <div>{t('list_is_not_found')}</div>;
      }
      return objKeys.map((propKey, index) => {
        return (
          <div key={index}>
            {checkDocType(providedDocument, propKey, modalShowState)}
          </div>
        );
      });
    }
  };

  const handleConditional = () => {
    setCheckBehavior(!checkBehavior);
    setProp((props) => {
      props.ifConditionalTrueHideElement = !checkBehavior;
    });
  };

  return (
    <Modal open={showModal.show} style={{ zIndex: '20000' }}>
      <Modal.Header>{t('select_condition')}</Modal.Header>
      <Modal.Content>
        <Header as='h4'>{t('condition_fields')}</Header>
        {getDocumentFields(documentJson, showModal)}
        <Divider section />
        <Header as='h4'>{t('condition_behavior')}</Header>
        <Checkbox
          label={t('ifConditionalTrueHideElement')}
          name={'ifConditionalTrueHideElement'}
          checked={checkBehavior}
          onClick={handleConditional}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>{t('cancel')}</Button>
        <Button onClick={onOkClicked}>{t('ok')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PlaceholderModal;
