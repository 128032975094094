import React from 'react';
import { useEditor } from '@craftjs/core';
import { Container, Button, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './ConfigPanel.module.scss';

const getSelectedPanel = (state, query) => {
  const currentSelectedNodes = [];
  state.events.selected.forEach(item => currentSelectedNodes.push(item));
  let selected;

  if (currentSelectedNodes.length) {
    let currentSelected = currentSelectedNodes[0];
    selected = {
      id: currentSelected,
      name: state.nodes[currentSelected].data.name,
      settings:
        state.nodes[currentSelected].related &&
        state.nodes[currentSelected].related.settings,
      isDeletable: query.node(currentSelected).isDeletable()
    };
  }
  return { selected };
};

const ConfigPanel = () => {
  const { actions, selected } = useEditor(getSelectedPanel);
  const { t } = useTranslation();
  return selected ? (
    <Container fluid>
      {selected?.settings && React.createElement(selected?.settings)}
      {selected?.isDeletable && (
        <Button
          className={styles.button}
          onClick={() => actions.delete(selected?.id)}
        >
          {t('delete_btn')}
        </Button>
      )}
    </Container>
  ) : (
    <Message warning>
      <Message.Header>{t('message_header')}</Message.Header>
      <p>{t('message_text')}</p>
    </Message>
  );
};

export default ConfigPanel;
