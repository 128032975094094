import React from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const FieldsCheck = ({ config, setProp, fieldValue, inline, label }) => {
  const { t } = useTranslation();

  const handleCheck = (checked, fieldName) =>
    setProp(props => {
      props[fieldName] = checked;
    });

  return (
    <Form>
      <Form.Field label={label} />
      <Form.Group inline={inline}>
        {config.map(el => (
          <Form.Field
            key={el?.name}
            control={el?.control}
            label={t(el?.label)}
            checked={fieldValue[el?.name]}
            onChange={(_, d) => handleCheck(d.checked, el?.name)}
          />
        ))}
      </Form.Group>
    </Form>
  );
};

export default FieldsCheck;
