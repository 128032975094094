import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-semantic-toasts';
import { useTranslation } from 'react-i18next';

const useToast = () => {
  const activeToast = useRef(false);
  const [toastType, setToastType] = useState(null);
  const { t } = useTranslation();

  const onClose = () => {
    activeToast.current = false;
    setToastType(null);
  };

  function getOptions(type) {
    switch (type) {
      case 'error':
        return {
          type: 'error',
          title: t('error_title', 'Error'),
          description: t('server_error_message', 'Unknown server error'),
          animation: 'fly left',
          time: 1000,
          onClose
        };
      case 'edit':
        return {
          type: 'success',
          title: t('success_title'),
          description: t('successful_update_message'),
          animation: 'fly right',
          time: 2000,
          onClose
        };
      case 'created':
        return {
          type: 'success',
          title: t('success_title'),
          description: t('successful_creation_message'),
          animation: 'fly right',
          time: 2000,
          onClose
        };
      default:
        return null;
    }
  }

  useEffect(() => {
    if (toastType && !activeToast.current) {
      activeToast.current = true;
      toast(getOptions(toastType));
      setToastType(null);
    }
  }, [toastType]); // eslint-disable-line react-hooks/exhaustive-deps

  const sendToast = type => setToastType(type);

  return sendToast;
};

export default useToast;
