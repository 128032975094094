import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Ref from 'semantic-ui-react/dist/commonjs/addons/Ref/Ref';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Image from 'semantic-ui-react/dist/commonjs/elements/Image/Image';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import { useTranslation } from 'react-i18next';
import { useEditor, useNode } from '@craftjs/core';

function CraftImage({ src }) {
  const { connectors } = useEditor();
  const innerRef = (ref) => connectors.create(ref, <CanvasImage src={src} />);
  return (
    <Ref innerRef={innerRef}>
      <Image src={src} fluid style={{ height: '120px' }} />
    </Ref>
  );
}
CraftImage.propTypes = { src: PropTypes.string.isRequired };

export const CanvasImage = ({ src, width, cellAlign }) => {
  const {
    connectors: { connect, drag },
    actions: { setCustom }
  } = useNode();

  const { t } = useTranslation();

  useEffect(() => {
    setCustom((props) => {
      props.displayName = t('image');
    });
  }, [setCustom, t]);

  return (
    <Ref innerRef={(ref) => connect(drag(ref))}>
      <img
        src={src}
        alt='img'
        style={{
          width: `${width}`,
          height: 'auto',
          display: 'block',
          pageBreakAfter: 'avoid',
          float: `${cellAlign}`
        }}
      />
    </Ref>
  );
};

function CanvasImageSettings() {
  const {
    actions: { setProp },
    width,
    cellAlign
  } = useNode(({ data: { props } }) => ({
    width: props.width,
    cellAlign: props.cellAlign
  }));

  const { t } = useTranslation();

  // eslint-disable-next-line no-param-reassign
  const handleSizeChange = (event, { value: imgSize }) => {
    setProp((props) => {
      props.width = imgSize;
    }, 500);
  };

  const onAlignButton = (e, data) => {
    setProp((props) => {
      props.cellAlign = data.name;
    }, 500);
  };
  return (
    <Form>
      <h5 style={{ fontSize: '13px', marginBottom: '-3px' }}>
        {t('image_align')}
      </h5>
      <Button.Group style={{ margin: '1rem 0.25rem 2rem 0' }}>
        <Button
          icon
          onClick={onAlignButton}
          name='left'
          active={cellAlign === 'left'}
        >
          <Icon name='align left' />
        </Button>

        <Button
          icon
          onClick={onAlignButton}
          name='right'
          active={cellAlign === 'right'}
        >
          <Icon name='align right' />
        </Button>
      </Button.Group>
      <Form.Select
        label={t('size')}
        value={width}
        options={[
          { key: 'fit', text: 'fit', value: '100%' },
          { key: 'mini', text: t('mini'), value: '35px' },
          { key: 'tiny', text: t('tiny'), value: '80px' },
          { key: 'small', text: t('small'), value: '150px' },
          { key: 'smallPlus', text: t('smallPlus'), value: '220px' },
          { key: 'medium', text: t('medium'), value: '300px' },
          { key: 'large', text: t('large'), value: '450px' },
          { key: 'big', text: t('big'), value: '600px' }
        ]}
        onChange={handleSizeChange}
      />
    </Form>
  );
}

const CanvasImageDefaultProps = { width: '100%' };

CanvasImage.defaultProps = CanvasImageDefaultProps;

CanvasImage.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.string
};

CanvasImage.craft = {
  props: CanvasImageDefaultProps,
  related: { settings: CanvasImageSettings }
};

export default CraftImage;
