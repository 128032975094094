const filterEmptyStrings = filterObject => {
  if (!filterObject) return;
  let checkedObj = { ...filterObject };
  // eslint-disable-next-line array-callback-return
  Object.keys(checkedObj).map(column => {
    if (
      checkedObj[column] === undefined ||
      checkedObj[column] === null ||
      checkedObj[column] === ''
    )
      delete checkedObj[column];
  });
  return checkedObj;
};

export { filterEmptyStrings };
