import React from 'react';
import { Form } from 'semantic-ui-react';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';

const FieldSketch = ({ background, label, setProp }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-param-reassign
  const handleBackgroundColor = (color) => {
    setProp((props) => {
      props.background = color.hex;
    }, 500);
  };

  return (
    <Form>
      <Form.Field
        label={t(label)}
        control={SketchPicker}
        color={background}
        // eslint-disable-next-line no-param-reassign
        onChangeComplete={handleBackgroundColor}
      />
    </Form>
  );
};

export default FieldSketch;
