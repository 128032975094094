import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import ColumnHeader from '../Layout/ColumnHeader/ColumnHeader';
import styles from './EditorHeader.module.scss';

const EditorHeader = ({
  templateName,
  languages,
  activeLang,
  setActiveLang
}) => {
  useEffect(() => {
    const activeLangExistsInLanguages = !!languages.find(
      ({ language }) => language === activeLang
    );
    if (languages.length && (!activeLang || !activeLangExistsInLanguages)) {
      setActiveLang(undefined, { value: languages[0].language });
    }
  }, [languages]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ColumnHeader>
      <Grid columns="equal">
        <Grid.Column className={styles.title}>{templateName}</Grid.Column>
        <Grid.Column textAlign="right">
          {/* <Dropdown
            disabled={!activeLang}
            inline
            value={activeLang}
            options={languages.map(({ language }) => ({ key: language, text: language, value: language }))}
            onChange={setActiveLang}
          /> */}
        </Grid.Column>
      </Grid>
    </ColumnHeader>
  );
};

EditorHeader.defaultProps = { languages: [] };

EditorHeader.propTypes = {
  setActiveLang: PropTypes.func.isRequired,
  templateName: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.object),
  activeLang: PropTypes.string.isRequired
};

export default EditorHeader;
