import React from 'react';
import PropTypes from 'prop-types';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment/Segment';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';
import { useTranslation } from 'react-i18next';
import { getFieldData } from '../../../../utils/detailsHelpers';
import BottomLine from '../../BottomLine/BottomLine';
import styles from './ViewList.module.scss';

const ViewList = ({ fields, details }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.view}>
      {details ? (
        fields
          .filter(({ inMainTable }) => inMainTable)
          .map(({ fieldNameKey, propName, propPath }) => (
            <Segment vertical key={propName}>
              <Grid columns='equal'>
                <Grid.Column>{t(fieldNameKey)}</Grid.Column>
                <Grid.Column textAlign='right'>
                  {getFieldData(details, propName, propPath, true)}
                </Grid.Column>
              </Grid>
            </Segment>
          ))
      ) : (
        <Segment vertical disabled />
      )}
      <BottomLine />
    </div>
  );
};

ViewList.defaultProps = { details: null };

ViewList.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)])
  ).isRequired,
  details: PropTypes.shape({})
};

export default ViewList;
