export const formatTemplateById = res => {
 
  let resData = res?.data ?? {};
  return {
    id: resData?.id ?? '',
    name: resData?.name ?? '',
    applicationId: resData?.application?.id ?? '',
    applicationDescription: resData?.application?.description ?? '',
    formTypeDescription: resData?.source?.type ?? '',
    sourceId: resData?.templateSource?.id ?? '',
    // sourceName: ,
    description: resData?.description ?? '',
    templateSource:{ name: resData?.templateSource?.name ?? '',
                     application:{name:resData?.templateSource?.application?.name ?? ''} }
  };
};

export const getEmptyTemplate = () => {
  return {
    id: '',
    formName: '',
    applicationId: '',
    applicationDescription: '',
    formTypeDescription: '',
    formTypeId: '',
    sourceId: '',
    sourceName: '',
    description: ''
  };
};

const isArray = a => Array.isArray(a);

export const formatTypeAndAppOptions = list => {
  if (isArray(list)) {
    return list.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id
    }));
  }
  return [];
};
