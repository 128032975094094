import { useState, useEffect } from 'react';

function useStateFromProps(props, withSetter = false) {
  const [state, setState] = useState(props);

  useEffect(() => {
    setState(props);
  }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

  return withSetter ? [state, setState] : state;
}

export default useStateFromProps;
