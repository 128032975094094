import axios from 'axios';
import { template, templateSettings } from 'lodash';

import { API } from '../common/network/ApiPaths';
import { filterEmptyStrings } from '../common/helpers/helpers';

const baseURL = API.BASE_URL;
const headers = {
  'Content-Type': 'application/json'
};

const getDefaultOptions = ({
  method = 'get',
  params = undefined,
  data = undefined,
  url = undefined,
  responseType = undefined
}) => {
  return {
    headers,
    baseURL,
    method,
    responseType,
    params,
    data,
    url
  };
};
// pathVariables - is object that contains key value pair that will replace :key name in path (as variable)
export const insertPathVariables = (path, pathVariables) => {
  if (!pathVariables) {
    return path;
  }
  templateSettings.interpolate = /:([A-z]*)/g;
  // replacing the ':param' with param data
  return template(path)(pathVariables);
};

// params - is object with key value pairs that will be serialized to query string
export const getByPathAndParams = ({
  path = '/',
  params,
  pathVariables,
  ...rest
} = {}) => {
  return axios(
    getDefaultOptions({
      params: filterEmptyStrings(params),
      url: insertPathVariables(path, pathVariables),
      ...rest
    })
  );
};
// data - is value that will be sent as payload
export const putByPathAndData = ({
  path = '/',
  data,
  pathVariables,
  ...rest
} = {}) => {
  return axios(
    getDefaultOptions({
      url: insertPathVariables(path, pathVariables),
      data,
      method: 'put',
      ...rest
    })
  );
};

export const patchByPathAndData = ({
  path = '/',
  data,
  pathVariables
} = {}) => {
  return axios(
    getDefaultOptions({
      url: insertPathVariables(path, pathVariables),
      data,
      method: 'patch'
    })
  );
};

export const postByPathAndData = ({
  path = '/',
  data,
  pathVariables,
  ...rest
} = {}) => {
  return axios(
    getDefaultOptions({
      url: insertPathVariables(path, pathVariables),
      data,
      method: 'post',
      ...rest
    })
  );
};

export const postByPathVariables = ({
  path = '/',
  pathVariables,
  ...rest
} = {}) => {
  return axios(
    getDefaultOptions({
      url: insertPathVariables(path, pathVariables),
      method: 'post',
      ...rest
    })
  );
};

export const deleteByPath = ({ path = '/', pathVariables } = {}) => {
  return axios(
    getDefaultOptions({
      url: insertPathVariables(path, pathVariables),
      method: 'delete'
    })
  );
};

// configList - is array of path strings
export const getAllByPathList = async (configList = []) => {
  const response = [];
  configList.forEach((path) => {
    response.push(getByPathAndParams({ path }));
  });
  return await Promise.all(response);
};
