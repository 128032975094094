import { Checkbox } from 'semantic-ui-react';

export default [
  {
    name: 'isBorder',
    control: Checkbox,
    label: 'border',
    inline: true,
  },
  {
    name: 'isHeader',
    control: Checkbox,
    label: 'header',
    inline: true,
  },
  {
    name: 'isFooter',
    control: Checkbox,
    label: 'footer',
    inline: true,
  },
];
