import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown, Divider, Button, Checkbox } from 'semantic-ui-react';
import { useNode, useEditor } from '@craftjs/core';
import Placeholdermodal from './PlaceholderModal';
import { DocumentFieldsContext } from '../../Template/DocumentFieldsContext';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import TextStyle from './TextStyle';

export const Placeholder = ({
  fieldName,
  thymeleafAttributes,
  display,
  fontSize,
  textAlign,
  fontWeight,
  textDecoration,
  fontItalic,
  background,
  fontColor,
  padding,
  ifAtt
}) => {
  const { t } = useTranslation();

  const {
    connectors: { connect, drag },
    actions: { setCustom }
  } = useNode();

  useEffect(() => {
    setCustom((props) => {
      props.displayName = t('placeholder');
    });
  }, [setCustom, t]);

  let fileNameSplit = fieldName.split('.');

  let myPlaceholderText;
  const regexForMathOperations = /[+\-*/]/g;

  //change field name to readable text for user
  if (fieldName.includes('sum')) {
    const regex = /{([^}]*)}/;
    const matches = fieldName.match(regex);
    const extractedWord = matches[1];
    myPlaceholderText = `Sum ${extractedWord}`;
  } else if (fieldName.match(regexForMathOperations)) {
    const regex = /item\./g;
    myPlaceholderText = fieldName.replace(regex, '');
  } else {
    myPlaceholderText = fieldName
      ? fileNameSplit[fileNameSplit.length - 1]
      : t('placeholder');
  }

  return (
    <>
      <p
        ref={(ref) => connect(drag(ref))}
        role='presentation'
        style={{
          cursor: 'grab',
          margin: '0',
          display: display,
          fontSize: `${fontSize}px`,
          textAlign: `${textAlign}`,
          fontWeight: `${fontWeight ? 'bold' : '100'}`,
          textDecoration: `${textDecoration ? 'underline' : 'none'}`,
          fontStyle: `${fontItalic ? 'italic' : 'normal'}`,
          color: `${fontColor}`,
          backgroundColor: `${background}`,
          fontFamily: 'sans-serif',
          // overflow: 'hidden',
          flexGrow: `${display === 'block' ? '2' : ''}`,
          padding: `${padding}px`
        }}
      >
        {ifAtt !== '' ? (
          <span
            data-th-if={'${not #lists.isEmpty(' + ifAtt + ')}'}
            data-th-text={thymeleafAttributes}
          >
            {myPlaceholderText}
          </span>
        ) : (
          <span data-th-text={thymeleafAttributes}>{myPlaceholderText}</span>
        )}
      </p>
    </>
  );
};

export const PlaceholderSettings = () => {
  const { documentJson } = useContext(DocumentFieldsContext);
  const [showModal, setShowModal] = useState({
    show: false,
    listSource: '',
    parent: '',
    showRootFields: true,
    action: null
  });
  const { currentState } = useEditor((state) => ({
    currentState: state
  }));
  const {
    actions: { setProp },
    fieldName,
    display,
    parent,
    fontSize,
    textAlign,
    fontWeight,
    textDecoration,
    fontItalic,
    background,
    fontColor,
    currentFormula,
    padding,
    isNumber
  } = useNode(({ data }) => ({
    fieldName: data.props.fieldName,
    thymeleafAttributes: data.props.thymeleafAttributes,
    display: data.props.display,
    parent: data.parent,
    fontSize: data.props.fontSize,
    textAlign: data.props.textAlign,
    fontWeight: data.props.fontWeight,
    textDecoration: data.props.textDecoration,
    fontItalic: data.props.bool,
    background: data.props.background,
    fontColor: data.props.background,
    currentFormula: data.props.currentFormula,
    padding: data.props.padding,
    isNumber: data.props.isNumber,
    ifAtt: data.props.ifAtt
  }));

  const { t } = useTranslation();

  const displayOptions = [
    {
      key: 'inline-block',
      value: 'inline-block',
      text: t('inline_block')
    },
    { key: 'block', value: 'block', text: t('block') }
  ];

  const changeText = (newValue) => {
    if (get(showModal, 'action') === 'sum') {
      setProp((props) => {
        props.fieldName = `#aggregates.sum(${get(
          showModal,
          'listSource'
        )}.{${newValue}})`;
      }, 500);
      return;
    }
    if (get(showModal, 'action') === 'cals') {
      setProp((props) => {
        props.fieldName = `#aggregates.sum(${get(
          showModal,
          'listSource'
        )}.{${newValue}})`;
      }, 500);
      return;
    }
    setProp((props) => {
      props.fieldName = newValue;
    }, 500);
  };

  const onChange = (_, data) => {
    setProp((props) => {
      props.display = data.value;
    }, 500);
  };

  const onChecked = (checked, field) =>
    setProp((props) => {
      props[field] = checked;
    });

  const onCalcSave = (value) => {
    setProp((props) => {
      props.currentFormula = value;
    }, 500);
  };
  const onChangeRange = (e) => {
    switch (e.target.name) {
      case 'fontSize':
        setProp((props) => {
          props[e.target.name] = Number(e.target.value);
        }, 500);
        break;
      default:
        setProp((props) => {
          props[e.target.name] = e.target.value;
        }, 500);
    }
  };

  const onClick = (e, data) => {
    let listId =
      currentState.nodes[parent]?.dom?.localName !== 'th'
        ? currentState.nodes[parent]?.data?.parent
        : null;
    let listSrc = currentState.nodes[listId]?.data?.props?.listSrc;
    setShowModal({
      show: true,
      listSource: listSrc,
      parent: '',
      showRootFields: typeof listSrc === 'undefined',
      action: data.name
    });
  };

  const disableSum =
    currentState.nodes[parent].dom?.localName !== 'td'
      ? true
      : fieldName
      ? !fieldName.includes('aggregates')
      : false;
  const disableCalc =
    currentState.nodes[parent].dom?.localName !== 'td'
      ? true
      : fieldName
      ? fieldName.includes('aggregates')
      : false;

  useEffect(() => {
    if (isNumber) {
      setProp((props) => {
        props.thymeleafAttributes =
          '${#numbers.formatDecimal(' +
          fieldName +
          ", 1, 'COMMA', 2, 'POINT')}";
      });
      return;
    }

    setProp((props) => {
      props.thymeleafAttributes = '${' + fieldName + '}';
    });
  }, [isNumber, fieldName, setProp]);

  return (
    <div>
      <Placeholdermodal
        currentValue={fieldName}
        currentFormula={currentFormula}
        documentJson={documentJson}
        showModal={showModal}
        setShowModal={setShowModal}
        changeTextFunc={changeText}
        onCalcSave={onCalcSave}
        setProp={setProp}
      />

      <Form>
        <Form.Field>
          <h5>{`${t('property_name')}: ${fieldName}`}</h5>
        </Form.Field>
        <Form.Field>
          <Button name='source' onClick={onClick}>
            {t('select_source')}
          </Button>
          <Button name='calc' disabled={disableCalc} onClick={onClick}>
            {t('calc')}
          </Button>
          <Button name='sum' disabled={disableSum} onClick={onClick}>
            {t('sum')}
          </Button>
        </Form.Field>
      </Form>
      <TextStyle
        textAlign={textAlign}
        fontWeight={fontWeight}
        textDecoration={textDecoration}
        fontItalic={fontItalic}
        background={background}
        fontColor={fontColor}
        setProp={setProp}
        popupContent={display !== 'block' ? t('select_block') : null}
      />
      <Form>
        <Form.Input
          label={`${t('font_size')} ${fontSize}`}
          type='range'
          min={5}
          max={60}
          value={fontSize}
          name='fontSize'
          // eslint-disable-next-line no-param-reassign
          onChange={onChangeRange}
        />
        <Form.Input
          label={`${t('padding')}: ${padding}px`}
          type='range'
          min={0}
          max={500}
          value={padding}
          // eslint-disable-next-line no-param-reassign
          onChange={(value) => {
            setProp((props) => {
              props.padding = value.target.value;
            }, 500);
          }}
        />
        <Form.Field
          key={'isNumber'}
          control={Checkbox}
          label={t('is_number')}
          checked={isNumber}
          onChange={(_, d) => onChecked(d.checked, 'isNumber')}
        />

        <Form.Field>
          <Form.Input
            label={t('display')}
            control={Dropdown}
            onChange={onChange}
            inline
            value={display}
            options={displayOptions}
          />
        </Form.Field>
        <Divider hidden />
      </Form>
    </div>
  );
};

export const PlaceholderDefaultProps = {
  fieldName: '',
  thymeleafAttributes: '',
  display: 'block',
  isInList: 'def',
  fontSize: 12,
  padding: '5',
  isNumber: false,
  ifAtt: ''
};

Placeholder.defaultProps = PlaceholderDefaultProps;

Placeholder.propTypes = {
  fieldName: PropTypes.string,
  thymeleafAttributes: PropTypes.string,
  display: PropTypes.string,
  isInList: PropTypes.string,
  fontSize: PropTypes.number,
  padding: PropTypes.string,
  isNumber: PropTypes.bool,
  ifAtt: PropTypes.string
};

Placeholder.craft = {
  props: PlaceholderDefaultProps,
  related: { settings: PlaceholderSettings }
};
