import React, { useEffect, useState, useContext } from 'react';
import { useNode } from '@craftjs/core';
import Placeholdermodal from './PlaceholderModal';
import { DocumentFieldsContext } from '../../Template/DocumentFieldsContext';
import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';
import { Form, Divider, Dropdown, Ref, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import TextStyle from './TextStyle';

export const Input = ({
  text,
  conditionalFieldName,
  fontSize,
  fontItalic,
  textAlign,
  background,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  fontWeight,
  textDecoration,
  fontColor,
  display
}) => {
  const {
    connectors: { connect, drag },
    actions: { setCustom, setProp }
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged
  }));

  const { t } = useTranslation();

  useEffect(() => {
    setCustom((props) => {
      props.displayName = t('text');
    });
  }, [setCustom, t]);

  const handleOnChange = (e) => {
    setProp((props) => {
      props.text = e.target.value;
    }, 500);
  };

  const textComponent = (
    <ContentEditable
      html={text}
      // eslint-disable-next-line no-param-reassign
      onChange={handleOnChange}
      tagName='div'
      style={{
        display: display,
        fontSize: `${fontSize}px`,
        width: '100%',
        margin: '0',
        paddingLeft: `${paddingLeft}px`,
        paddingRight: `${paddingRight}px`,
        paddingTop: `${paddingTop}px`,
        paddingBottom: `${paddingBottom}px`,
        textAlign: `${textAlign}`,
        fontWeight: `${fontWeight ? 'bold' : '100'}`,
        textDecoration: `${textDecoration ? 'underline' : 'none'}`,
        fontStyle: `${fontItalic ? 'italic' : 'normal'}`,
        color: `${fontColor}`,
        backgroundColor: `${background}`,
        lineHeight: '1.3',
        fontFamily: 'sans-serif',
        flexGrow: `${display === 'block' ? '2' : ''}`
      }}
    />
  );

  return (
    <Ref innerRef={(ref) => connect(drag(ref))}>
      <span
        data-th-if={`${
          !!conditionalFieldName ? '${' + conditionalFieldName + '}' : 'true'
        } `}
      >
        {textComponent}
      </span>
    </Ref>
  );
};

export const InputSettings = () => {
  const { documentJson } = useContext(DocumentFieldsContext);
  const [showModal, setShowModal] = useState({
    show: false,
    listSource: '',
    parent: '',
    showRootFields: true,
    action: null
  });

  const {
    actions: { setProp },
    conditionalFieldName,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    textAlign,
    fontWeight,
    textDecoration,
    fontItalic,
    fontSize,
    background,
    fontColor,
    display
  } = useNode(({ data: { props } }) => ({
    props: props,
    conditionalFieldName: props.conditionalFieldName,
    paddingLeft: props.paddingLeft,
    paddingRight: props.paddingRight,
    paddingTop: props.paddingTop,
    paddingBottom: props.paddingBottom,
    textAlign: props.textAlign,
    fontWeight: props.fontWeight,
    textDecoration: props.textDecoration,
    fontItalic: props.bool,
    fontSize: props.fontSize,
    background: props.background,
    fontColor: props.background,
    display: props.display
  }));

  const { t } = useTranslation();

  const displayOptions = [
    {
      key: 'inline-block',
      value: 'inline-block',
      text: t('inline_block')
    },
    { key: 'block', value: 'block', text: t('block') },
    { key: 'inline', value: 'inline', text: t('inline') }
  ];

  const onChange = (e) => {
    switch (e.target.name) {
      case 'fontSize':
        setProp((props) => {
          props[e.target.name] = Number(e.target.value);
        }, 500);
        break;
      default:
        setProp((props) => {
          props[e.target.name] = e.target.value;
        }, 500);
    }
  };

  const onDisplayChange = (_, data) => {
    setProp((props) => {
      props.display = data.value;
    }, 500);
  };

  const changeText = (newValue) => {
    setProp((props) => {
      props.conditionalFieldName = newValue;
    }, 500);

    setShowModal({
      show: false,
      listSource: '',
      parent: ''
    });
  };

  const onClick = (e, data) => {
    setShowModal({
      show: true,
      listSource: '',
      parent: '',
      showRootFields: typeof listSrc === 'undefined',
      action: 'source'
    });
  };

  return (
    <div>
      <Placeholdermodal
        currentValue={conditionalFieldName}
        changeTextFunc={changeText}
        documentJson={documentJson}
        showModal={showModal}
        setShowModal={setShowModal}
        setProp={setProp}
      />

      <TextStyle
        textAlign={textAlign}
        fontWeight={fontWeight}
        textDecoration={textDecoration}
        fontItalic={fontItalic}
        background={background}
        fontColor={fontColor}
        setProp={setProp}
      />
      <Form>
        <Form.Field>
          <label>{t('select_condition')}</label>
          <Button name='source' onClick={onClick}>
            {t('condition_fields')}
          </Button>
        </Form.Field>
        <Form.Input
          label={`${t('font_size')} ${fontSize}`}
          type='range'
          min={5}
          max={60}
          value={fontSize}
          name='fontSize'
          // eslint-disable-next-line no-param-reassign
          onChange={onChange}
        />
        <Form.Group widths='equal'>
          <Form.Input
            label={t('padding_left')}
            type='range'
            min={0}
            max={500}
            value={paddingLeft}
            name='paddingLeft'
            // eslint-disable-next-line no-param-reassign
            onChange={onChange}
          />
          <Form.Input
            label={t('padding_right')}
            type='range'
            min={0}
            max={500}
            value={paddingRight}
            name='paddingRight'
            // eslint-disable-next-line no-param-reassign
            onChange={onChange}
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input
            label={t('padding_top')}
            type='range'
            min={0}
            max={500}
            value={paddingTop}
            name='paddingTop'
            // eslint-disable-next-line no-param-reassign
            onChange={onChange}
          />
          <Form.Input
            label={t('padding_bottom')}
            type='range'
            min={0}
            max={500}
            value={paddingBottom}
            name='paddingBottom'
            // eslint-disable-next-line no-param-reassign
            onChange={onChange}
          />
        </Form.Group>
        <Form.Field>
          <Form.Input
            label={t('display')}
            control={Dropdown}
            onChange={onDisplayChange}
            inline
            value={display}
            options={displayOptions}
          />
        </Form.Field>
        <Divider hidden />
      </Form>
    </div>
  );
};

export const TextDefaultProps = {
  text: 'Text...',
  conditionalFieldName: null,
  fontStyle: 'Helvetica, Arial, sans-serif',
  fontSize: 12,
  paddingLeft: '5',
  paddingRight: '5',
  paddingTop: '5',
  paddingBottom: '5',
  textAlign: 'left',
  fontWeight: false,
  textDecoration: false,
  fontItalic: false,
  background: 'transparent',
  fontColor: '#000000',
  display: 'inline-block'
};

Input.defaultProps = TextDefaultProps;

Input.propTypes = {
  text: PropTypes.string,
  conditionalFieldName: PropTypes.string,
  fontSize: PropTypes.number,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  textAlign: PropTypes.string,
  fontWeight: PropTypes.bool,
  textDecoration: PropTypes.bool,
  fontItalic: PropTypes.bool,
  background: PropTypes.string,
  fontColor: PropTypes.string,
  display: PropTypes.string
};

Input.craft = {
  props: TextDefaultProps,
  related: {
    settings: InputSettings
  },
  rules: {
    canMoveIn: (node, self, helper) => {
      return node.data.name === 'Placeholder';
    }
  }
};
