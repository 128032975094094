import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Ref, Form, Button } from 'semantic-ui-react';
import { useNode } from '@craftjs/core';
import { useTranslation } from 'react-i18next';
import { gridCellFields } from './util/elementConfig';
import ConditionalModal from './ConditionalModal';
import { DocumentFieldsContext } from '../../Template/DocumentFieldsContext';
import style from './GridCell.module.scss';
import FieldsRange from '../../Settings/FieldsRange';
import FieldsCheck from '../../Settings/FieldsCheck';
import FieldSketch from '../../Settings/FieldSketch';

export const GridCell = ({
  cellWidth,
  conditionalFieldName,
  children,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  background,
  borderWidth,
  borderLeft,
  borderRight,
  borderTop,
  borderBottom,
  height,
  ifConditionalTrueHideElement
}) => {
  const {
    connectors: { connect, drag },
    actions: { setCustom }
  } = useNode();
  const { t } = useTranslation();

  useEffect(() => {
    setCustom((props) => {
      props.displayName = t('grid_cell');
    }, 500);
  }, [setCustom, t]);
  return (
    <Ref innerRef={(ref) => connect(drag(ref))}>
      {ifConditionalTrueHideElement ? (
        <div
          className={style.border}
          data-th-if={`${
            !!conditionalFieldName ? '${' + conditionalFieldName + '}' : 'true'
          } `}
          style={{
            float: 'left',
            display: 'block',
            minHeight: `${height}px`,
            backgroundColor: background,
            width: `${cellWidth}%`,
            paddingLeft: `${paddingLeft}px`,
            paddingRight: `${paddingRight}px`,
            paddingTop: `${paddingTop}px`,
            paddingBottom: `${paddingBottom}px`,
            borderLeft: `${borderLeft ? borderWidth : null}px solid #000000`,
            borderRight: `${borderRight ? borderWidth : null}px solid #000000`,
            borderTop: `${borderTop ? borderWidth : null}px solid #000000`,
            borderBottom: `${borderBottom ? borderWidth : null}px solid #000`
          }}
        >
          {children}
        </div>
      ) : (
        <div
          className={style.border}
          data-th-switch={'${' + conditionalFieldName + '}'}
          style={{
            float: 'left',
            display: 'block',
            minHeight: `${height}px`,
            backgroundColor: background,
            width: `${cellWidth}%`,
            paddingLeft: `${paddingLeft}px`,
            paddingRight: `${paddingRight}px`,
            paddingTop: `${paddingTop}px`,
            paddingBottom: `${paddingBottom}px`,
            borderLeft: `${borderLeft ? borderWidth : null}px solid #000000`,
            borderRight: `${borderRight ? borderWidth : null}px solid #000000`,
            borderTop: `${borderTop ? borderWidth : null}px solid #000000`,
            borderBottom: `${borderBottom ? borderWidth : null}px solid #000`
          }}
        >
          <span data-th-case='false'></span>
          <span data-th-case='*'>{children}</span>
        </div>
      )}
    </Ref>
  );
};

export const GridCellSettings = () => {
  const { documentJson } = useContext(DocumentFieldsContext);
  const [showModal, setShowModal] = useState({
    show: false,
    listSource: '',
    parent: '',
    showRootFields: true,
    action: null
  });

  const {
    actions: { setProp },
    conditionalFieldName,
    ifConditionalTrueHideElement,
    background,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    cellWidth,
    borderWidth,
    borderLeft,
    borderBottom,
    borderTop,
    borderRight,
    height
  } = useNode(({ data: { props } }) => ({
    elementProps: props.elementProps,
    conditionalFieldName: props.conditionalFieldName,
    background: props.background,
    paddingLeft: props.paddingLeft,
    paddingRight: props.paddingRight,
    paddingTop: props.paddingTop,
    paddingBottom: props.paddingBottom,
    cellWidth: props.cellWidth,
    borderWidth: props.borderWidth,
    borderLeft: props.borderLeft,
    borderBottom: props.borderBottom,
    borderTop: props.borderTop,
    borderRight: props.borderRight,
    height: props.height,
    ifConditionalTrueHideElement: props.ifConditionalTrueHideElement
  }));

  const { t } = useTranslation();

  const grouped = gridCellFields.filter((el) => el.group);
  const inlineCheckBox = gridCellFields.filter((el) => el.inline);

  const fieldValue = {
    cellWidth: cellWidth,
    paddingLeft: paddingLeft,
    paddingRight: paddingRight,
    paddingTop: paddingTop,
    paddingBottom: paddingBottom,
    borderWidth: borderWidth,
    borderLeft: borderLeft,
    borderRight: borderRight,
    borderTop: borderTop,
    borderBottom: borderBottom,
    height: height
  };

  const changeText = (newValue) => {
    if (newValue === '') {
      newValue = true;
    }
    setProp((props) => {
      props.conditionalFieldName = newValue;
    }, 500);

    setShowModal({
      show: false,
      listSource: '',
      parent: ''
    });
  };
  const onClick = (e, data) => {
    setShowModal({
      show: true,
      listSource: '',
      parent: '',
      showRootFields: typeof listSrc === 'undefined',
      action: 'source'
    });
  };

  return (
    <>
      <ConditionalModal
        currentValue={conditionalFieldName}
        changeTextFunc={changeText}
        documentJson={documentJson}
        showModal={showModal}
        setShowModal={setShowModal}
        setProp={setProp}
        ifConditionalTrueHideElement={ifConditionalTrueHideElement}
      />
      <FieldsRange config={grouped} setProp={setProp} fieldValue={fieldValue} />
      <FieldsCheck
        config={inlineCheckBox}
        setProp={setProp}
        fieldValue={fieldValue}
        inline={true}
        label={t('border')}
      />
      <Form style={{ margin: '1.5rem 0' }}>
        <Form.Field>
          <label>{t('select_condition')}</label>
          <Button name='source' onClick={onClick}>
            {t('condition_fields')}
          </Button>
        </Form.Field>
      </Form>
      <FieldSketch
        background={background}
        label={t('background')}
        setProp={setProp}
      />
    </>
  );
};

export const GridCellDefaultProps = {
  children: null,
  conditionalFieldName: true,
  paddingLeft: '10',
  paddingRight: '10',
  paddingTop: '10',
  paddingBottom: '10',
  background: 'transparent',
  cellWidth: '25',
  borderWidth: '1',
  borderLeft: false,
  borderBottom: false,
  borderTop: false,
  borderRight: false,
  height: '40',
  ifConditionalTrueHideElement: false
};

GridCell.defaultProps = GridCellDefaultProps;

GridCell.propTypes = {
  children: PropTypes.node,
  conditionalFieldName: PropTypes.string,
  size: PropTypes.number,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  background: PropTypes.string,
  cellWidth: PropTypes.string,
  borderWidth: PropTypes.string,
  borderLeft: PropTypes.bool,
  borderBottom: PropTypes.bool,
  borderTop: PropTypes.bool,
  borderRight: PropTypes.bool,
  height: PropTypes.string,
  ifConditionalTrueHideElement: PropTypes.bool
};

GridCell.craft = {
  props: GridCellDefaultProps,
  related: {
    settings: GridCellSettings
  }
};
