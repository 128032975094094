import React, { createContext } from 'react';

const DocumentFieldsContext = createContext();
const DocumentFieldsContextProvider = props => {
  const { documentJson } = props;
  const value = {
    documentJson
  };
  
  return (
    <DocumentFieldsContext.Provider value={value}>
      {props.children}
    </DocumentFieldsContext.Provider>
  );
};

export { DocumentFieldsContext, DocumentFieldsContextProvider };
