import React, { useState, useContext } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Tab from 'semantic-ui-react/dist/commonjs/modules/Tab/Tab';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Label from 'semantic-ui-react/dist/commonjs/elements/Label/Label';
import { useEditor } from '@craftjs/core';
import { useTranslation } from 'react-i18next';
import Toolbox from './Toolbox';
import ConfigPanel from './ConfigPanel';
import Attachments from './Attachments';
import DemoDataForm from './DemoDataForm';
import Images from './Images';
import TopButtons from './TopButtons';
import styles from './TabPanel.module.scss';
import { DocumentFieldsContext } from '../Template/DocumentFieldsContext';

const getSelectedPanel = state => {
  const currentNodeId = state.events.selected;
  return { currentNodeId };
};

const TabPanel = ({
  attachmentsData,
  ceRef,
  onSave,
  onBack,
  previewDoc,
  setShowPreview
}) => {
  const { documentJson } = useContext(DocumentFieldsContext);
  const { currentNodeId } = useEditor(getSelectedPanel);
  const [selectedPanel, setSelectedPanel] = useState(0);
  const [lastSelection, setLastSelection] = useState(currentNodeId);
  const [demoData, setDemoData] = useState(documentJson);

  if (currentNodeId !== lastSelection) {
    setSelectedPanel(1);
    setLastSelection(currentNodeId);
  }

  const { t } = useTranslation();
  const panes = [
    {
      menuItem: t('tab_components'),
      render: () => (
        <Tab.Pane className={styles.tabContainer}>
          <Toolbox />
        </Tab.Pane>
      )
    },
    {
      menuItem: t('tab_config'),
      render: () => (
        <Tab.Pane className={styles.tabContainer}>
          <ConfigPanel />
        </Tab.Pane>
      )
    },
    {
      menuItem: t('image_tab'),
      render: () => (
        <Tab.Pane className={styles.tabContainer}>
          <Images />
        </Tab.Pane>
      )
    },
    {
      menuItem: t('demo_data'),
      render: () => (
        <Tab.Pane className={styles.tabContainer}>
          <DemoDataForm
            demoData={demoData}
            setDemoData={setDemoData}
            documentJson={documentJson}
          />
        </Tab.Pane>
      )
    }
  ];
  const attachmentsTab = {
    menuItem: (
      <Menu.Item key='attachments'>
        {t('tab_attachments')}
        {!!get(attachmentsData.langAttachments, 'binaries.length') && (
          <Label>
            {get(attachmentsData.langAttachments, 'binaries.length')}
          </Label>
        )}
      </Menu.Item>
    ),
    render: () => (
      <Tab.Pane className={styles.tabContainer}>
        <Attachments attachmentsData={attachmentsData} />
      </Tab.Pane>
    )
  };
  if (attachmentsData.formType === 'Email') panes.push(attachmentsTab);

  const changeTab = e => {
    setSelectedPanel(e.target.value);
  };
  return (
    <>
      <TopButtons
        contentRef={ceRef}
        onSave={onSave}
        onBack={onBack}
        demoData={demoData}
        previewDoc={previewDoc}
        setShowPreview={setShowPreview}
      />
      <Tab panes={panes} activeIndex={selectedPanel} onTabChange={changeTab} />
    </>
  );
};

TabPanel.propTypes = {
  attachmentsData: PropTypes.shape({
    formType: PropTypes.string,
    langAttachments: PropTypes.shape({}),
    attachmentsAction: PropTypes.func
  }).isRequired
};

export default TabPanel;
