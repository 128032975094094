import { get, find } from 'lodash';
import detectBrowserLanguage from 'detect-browser-language';

export const constructLangUrl = (match, language) => {
  const splitUrl = match.url.split('/');
  if (match.params.lang) {
    splitUrl.pop();
  }
  const previousRoute = splitUrl.join('/');
  return `${previousRoute}/${language.toLowerCase()}`;
};

const assignDraft = (draftType, textContext, newLang, copyTemplates) => {
  if (copyTemplates[newLang]) {
    return get(find(textContext, ['language', copyTemplates[newLang]]), draftType, '');
  }
  return '';
};

export const mergeEditChoicesWithTextContext = (textContext, inputLanguages, templateId, copyTemplates) => {
  const textContextWithDeletedMarked = [...textContext];
  textContext.forEach(({ language }, index) => {
    if (!inputLanguages.includes(language)) {
      textContextWithDeletedMarked[index].deleted = true;
    }
  });
  const newLanguages = inputLanguages
    .filter(lang => !(textContext.find(({ language }) => language === lang)))
    .map(lang => ({
      language: lang,
      templateId,
      htmlData: assignDraft('htmlData', textContext, lang, copyTemplates),
      jsonData: assignDraft('jsonData', textContext, lang, copyTemplates)
    }));
  return textContextWithDeletedMarked.concat(newLanguages);
};

export const getTempLengText = (textContext, activeLang) => {
  if (!textContext || !textContext.length || !activeLang) {
    return undefined;
  }
  return textContext.find(({ language }) => activeLang === language);
};

export function checkIfLangInTextContext(textContext, lang) {
  let indexOfTempLeng = -1;
  textContext.forEach(({ language }, index) => {
    if (language === lang) {
      indexOfTempLeng = index;
    }
  });
  return indexOfTempLeng;
}

export function getTextContextWithHTML(textContext, htmlData) {
  const tContext = [...textContext];
  const indexOfTempLeng = checkIfLangInTextContext(tContext, htmlData.language);
  if (tContext[indexOfTempLeng].text.trim() !== htmlData.text.trim()) {
    tContext[indexOfTempLeng] = htmlData;
  }
  return tContext;
}

export function prepareCopy({
  formName, formTypeId, description, categoryId,
  csTextContext, attachments
}, t) {
  const tContext = csTextContext.map(({
    languageId, language, jsonData, htmlData
  }) => ({
    languageId, language, jsonData, htmlData
  }));
  return {
    formName: `${t('copy_prefix')} ${formName}`,
    formTypeId,
    description,
    categoryId,
    attachments,
    csTextContext: tContext
  };
}

export function getBrowserLanguage() {
  let lng = detectBrowserLanguage();
  const split = lng.split('-');
  if (split.length) ([lng] = split);
  return lng;
}
