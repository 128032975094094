/* eslint-disable jsx-quotes */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import ColumnHeader from '../ColumnHeader/ColumnHeader';
import TableBody from './TableBody';
import useCheckboxes from '../../../hooks/Checkboxes/Checkboxes';
import styles from './Table.module.scss';
import useSorting from '../../../hooks/Sorting/Sorting';

export const Table = ({
  data,
  fields,
  handleRowClick,
  placeholder,
  disabled,
  selectedItem,
  checkboxes,
  useActivation,
  showInactive,
  goTo
}) => {
  const { onCheck, onCheckAll, checkIfAllSelected, checkIfSelected } =
    useCheckboxes(
      (data || []).map(({ id }) => id),
      get(checkboxes, 'backupSelected', () => {}),
      get(checkboxes, 'preselected', [])
    );
  const { sortedData, sortedBy, sortDirection, handleSort } = useSorting(data);
  const tableData = useMemo(
    () =>
      useActivation && showInactive === false && sortedData
        ? sortedData.filter(({ active }) => active)
        : sortedData,
    [useActivation, showInactive, sortedData]
  );
  const { t } = useTranslation();
  return (
    <div
      className={disabled ? `${styles.disabled} ${styles.table}` : styles.table}
    >
      <ColumnHeader>
        <Grid columns='equal'>
          {!!checkboxes && (
            <Grid.Column
              key='checkbox-select-all'
              width={checkboxes ? 'one' : undefined}
            >
              <Checkbox
                onChange={onCheckAll}
                checked={checkIfAllSelected()}
                disabled={!data || !data.length}
              />
            </Grid.Column>
          )}
          {fields
            .filter(({ inMainTable }) => inMainTable)
            .map(({ fieldNameKey, propName }) => (
              <Grid.Column
                key={fieldNameKey}
                onClick={handleSort(propName)}
                className={styles.column}
                width={propName === 'id' ? 'one' : undefined}
              >
                {t(fieldNameKey)}
                {sortedBy === propName && (
                  <Icon
                    name={`angle ${sortDirection === 'asc' ? 'up' : 'down'}`}
                  />
                )}
              </Grid.Column>
            ))}
          {!!goTo && <Grid.Column width='one' />}
        </Grid>
      </ColumnHeader>
      <TableBody
        placeholder={placeholder}
        data={tableData}
        fields={fields.filter(({ inMainTable }) => inMainTable)}
        handleRowClick={handleRowClick}
        selectedItem={selectedItem}
        checkboxes={checkboxes ? { onCheck, checkIfSelected } : undefined}
        useActivation={useActivation}
        showInactive={showInactive}
        goTo={goTo}
      />
    </div>
  );
};

Table.defaultProps = {
  useActivation: false,
  handleRowClick: () => {},
  placeholder: '',
  checkboxes: null,
  showInactive: undefined,
  disabled: false,
  data: null,
  selectedItem: null,
  goTo: null
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checkboxes: PropTypes.shape({
    onCheck: PropTypes.func,
    checkIfSelected: PropTypes.func
  }),
  showInactive: PropTypes.bool,
  handleRowClick: PropTypes.func,
  useActivation: PropTypes.bool,
  placeholder: PropTypes.string,
  goTo: PropTypes.func
};

export default Table;
