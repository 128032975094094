import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { flatten, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from 'semantic-ui-react';
import TemplateList from '../Layout/Table/Table';
import TemplateDetails from '../Layout/Details/Details';
import EditDetails from '../Layout/Details/EditDetails';
import Layout from '../Layout/Layout';
import useFetching from '../../hooks/Fetching/Fetching';
import useDataExtract from '../../hooks/DataExtract/DataExtract';
import myFields from '../../config/templates';
import styles from './Templates.module.scss';
import {
  getByPathAndParams,
  postByPathAndData,
  patchByPathAndData,
  postByPathVariables
} from '../../services/newServices';
import useToast from '../../hooks/Toast/Toast';
import {
  formatTemplateById,
  getEmptyTemplate,
  formatTypeAndAppOptions
} from './util';

import {
  TEMPLATES_PATHS,
  EXTERNAL_APPLICATIONS
} from '../../common/network/ApiPaths';

const PDF_TYPE = 'pdf';
const MAIL_TYPE = 'mail';

const FORM_TYPES = [
  {
    key: 1,
    text: PDF_TYPE,
    value: 1
  },
  {
    key: 2,
    text: MAIL_TYPE,
    value: 2
  }
];

const Templates = ({ history, match }) => {
  const [templates, loadingTemplates, reFetchTemplates] = useFetching(
    getByPathAndParams.bind(null, { path: TEMPLATES_PATHS.GET_ALL })
  );

  const [selectedTemplate, setSelectedTemplate] = useDataExtract(
    get,
    match,
    'params.id',
    null
  );
  const [templateDetails, setTemplateDetails] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);

  const sendToast = useToast();
  const [showInactive, setShowIncative] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState(myFields);
  const [panelState, setPanelState] = useState('details');

  const { t } = useTranslation();

  const fetchTemplateDetails = async () => {
    if (selectedTemplate) {
      setLoadingDetails(true);
      try {
        let response = await getByPathAndParams({
          path: TEMPLATES_PATHS.GET_BY_ID,
          pathVariables: { id: selectedTemplate }
        });
        setTemplateDetails(formatTemplateById(response));
      } catch (err) {
      } finally {
        setLoadingDetails(false);
      }
    }
  };

  useEffect(() => {
    fetchTemplateDetails();
  }, [selectedTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        setIsLoading(true);
        //WAITING BE SERVICE
        // let typeOptions = await getByPathAndParams({
        //   path: TEMPLATES_PATHS.GET_FORM_TYPES
        // });
        let appOptions = await getByPathAndParams({
          path: EXTERNAL_APPLICATIONS.GET_APPS
        });

        setFields((p) => {
          p[1][1].options = formatTypeAndAppOptions(appOptions?.data ?? []);
          //  p[2][0].options = formatTypeAndAppOptions(typeOptions?.data ?? []);
          p[2][0].options = FORM_TYPES;

          return p;
        });
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    if (panelState === 'details') reFetchTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelState]);

  const handleRowClick = (id) => () =>
    setSelectedTemplate(selectedTemplate === id ? null : id);

  const setEditMode = (status) => () => setPanelState(status);

  const onNewDocumentClick = () => setPanelState('create');

  const activationHandler = async () => {
    await postByPathAndData({
      path: TEMPLATES_PATHS.POST,
      data: {
        ...templateDetails,
        active: !templateDetails.active
      }
    });

    await reFetchTemplates();
    showInactive ? fetchTemplateDetails() : setSelectedTemplate(null);
  };

  const onShowInactiveCheck = () => setShowIncative(!showInactive);

  const copyHandler = async (copyTemplate) => {
    try {
      setIsLoading(true);
      const response = await postByPathVariables({
        path: TEMPLATES_PATHS.CLONE,
        pathVariables: {
          id: copyTemplate?.id,
          additionalPath: 'copy'
        }
      });

      if (response.status === 201) {
        sendToast('created');
        await reFetchTemplates();
        setSelectedTemplate(response?.data?.id);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const onEditTemplate = async (inputData) => {
    try {
      setIsLoading(true);
      const data = {
        name: inputData.name,
        description: inputData.description
      };

      let res = await patchByPathAndData({
        path: TEMPLATES_PATHS.PATCH_BY_ID,
        pathVariables: { id: templateDetails.id },
        data
      });
      if (res?.data?.id) {
        setPanelState('details');
        sendToast('edit');
        fetchTemplateDetails();
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const goToTemplate = (id) => () => history.push(`/templates/${id}/editor`);

  const onCreateNewTemplate = async (restInputData) => {
    try {
      setIsLoading(true);
      const data = {
        ...restInputData,
        jsonData: null,
        htmlData:
          '<!DOCTYPE html><html xmlns:th="http://www.thymeleaf.org" lang="EN-US"><head><title>TEST TEMPLATE</title></head><body></body></html>'
      };

      delete data.id;
      const response = await postByPathAndData({
        path: TEMPLATES_PATHS.POST,
        data
      });
      if (response?.data?.id) {
        setPanelState('details');
        sendToast('created');
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout
      loading={[
        loadingTemplates,
        loadingTemplates,
        loadingDetails || isLoading
      ]}
      top={
        <div className={styles.topBar}>
          <Checkbox
            label={t('inlude_inactive_templates_label')}
            checked={showInactive}
            onChange={onShowInactiveCheck}
            disabled={panelState !== 'details'}
          />
        </div>
      }
      left={
        <div>
          <Button
            className={styles.newTemplateBtn}
            content={t('new_template_button')}
            icon='add'
            labelPosition='left'
            primary
            onClick={onNewDocumentClick}
            disabled={panelState !== 'details'}
          />
        </div>
      }
      middle={
        <TemplateList
          disabled={panelState !== 'details'}
          useActivation
          showInactive={showInactive}
          placeholder={t('template_empty_table_message')}
          fields={flatten(fields)}
          data={templates ?? null}
          selectedItem={selectedTemplate}
          handleRowClick={handleRowClick}
          goTo={goToTemplate}
        />
      }
      right={
        panelState === 'details' ? (
          <TemplateDetails
            useActivation
            useCopy
            fields={fields}
            details={templateDetails}
            setEditMode={setEditMode}
            copyHandler={copyHandler}
            activationHandler={activationHandler}
          />
        ) : (
          <EditDetails
            panelState={panelState}
            header={t('edit_template_title')}
            fields={fields}
            setFields={setFields}
            details={
              panelState === 'create' ? getEmptyTemplate() : templateDetails
            }
            onCancelGoBack={setEditMode('details')}
            onSave={
              panelState === 'create' ? onCreateNewTemplate : onEditTemplate
            }
          />
        )
      }
    />
  );
};

Templates.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string })
  }).isRequired
};

export default Templates;
