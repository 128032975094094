import React from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const FieldsRange = ({ config, setProp, fieldValue }) => {
  const { t } = useTranslation();

  const onChange = e => {
    setProp(props => {
      props[e.target.name] = e.target.value;
    }, 500);
  };

  return (
    <Form>
      <Form.Group widths={2} style={{ flexWrap: 'wrap' }}>
        {config.map(el => (
          <Form.Field
            key={el?.name}
            control={el?.control}
            width={el?.width}
            size={el?.size}
            label={`${t(el?.label)} ${fieldValue[el?.name]}${el?.unit} `}
            type={el?.type}
            min={el?.min}
            max={el?.max}
            name={el?.name}
            value={fieldValue[el?.name]}
            onChange={onChange}
          />
        ))}
      </Form.Group>
    </Form>
  );
};

export default FieldsRange;
