import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Icon } from 'semantic-ui-react';
import { useNode, useEditor } from '@craftjs/core';
import { useTranslation } from 'react-i18next';

export const TableCell = ({
  children,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  cellAlign,
  elementProps
}) => {
  const {
    connectors: { connect },
    actions: { setCustom },
    parent
  } = useNode(({ data }) => ({
    parent: data.parent
  }));
  const { t } = useTranslation();

  const { currentState } = useEditor((state) => ({
    currentState: state
  }));

  let isBorder = currentState?.nodes[parent]?.data?.props?.isBorder;
  let isHeader = currentState?.nodes[parent]?.data?.props?.isHeader;
  let cellWidth =
    currentState?.nodes[parent]?.data?.props?.cellWidthArray[elementProps];

  useEffect(() => {
    setCustom((props) => {
      props.displayName = t('table_cell');
    });
  }, [setCustom, t]);

  return (
    <td
      ref={(ref) => connect(ref)}
      style={{
        fontSize: 'inherit',
        paddingLeft: `${paddingLeft}px`,
        paddingRight: `${paddingRight}px`,
        paddingTop: `${paddingTop}px`,
        paddingBottom: `${paddingBottom}px`,
        borderLeft: `${isBorder ? '1px solid #e0e1e2' : ''}`,
        borderBottom: `${isBorder ? '1px solid #e0e1e2' : ''}`,
        overflow: 'hidden',
        width: `${!isHeader ? cellWidth : ''}%`
      }}
    >
      <div style={{ float: `${cellAlign}` }}>{children}</div>
    </td>
  );
};

export const TableCellSettings = () => {
  const {
    actions: { setProp },
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    size,
    cellAlign
  } = useNode(({ data }) => ({
    paddingLeft: data.props.paddingLeft,
    paddingRight: data.props.paddingRight,
    paddingTop: data.props.paddingTop,
    paddingBottom: data.props.paddingBottom,
    size: data.props.size,
    cellAlign: data.props.cellAlign,
    parent: data.parent
  }));

  const { t } = useTranslation();

  const onChange = (e) => {
    setProp((props) => {
      props[e.target.name] = e.target.value;
    }, 500);
  };

  const onAlignButton = (e, data) => {
    setProp((props) => {
      props.cellAlign = data.name;
    }, 500);
  };

  return (
    <div>
      <Form>
        <h5 style={{ fontSize: '13px', marginBottom: '-3px' }}>
          {t('cell_align')}
        </h5>
        <Button.Group style={{ margin: '1rem 0.25rem 2rem 0' }}>
          <Button
            icon
            onClick={onAlignButton}
            name='left'
            active={cellAlign === 'left'}
          >
            <Icon name='align left' />
          </Button>

          <Button
            icon
            onClick={onAlignButton}
            name='right'
            active={cellAlign === 'right'}
          >
            <Icon name='align right' />
          </Button>
        </Button.Group>

        <Form.Group widths='equal'>
          <Form.Input
            label={t('padding_left')}
            type='range'
            min={0}
            max={500}
            value={paddingLeft}
            name='paddingLeft'
            // eslint-disable-next-line no-param-reassign
            onChange={onChange}
          />
          <Form.Input
            label={t('padding_right')}
            type='range'
            min={0}
            max={500}
            value={paddingRight}
            name='paddingRight'
            // eslint-disable-next-line no-param-reassign
            onChange={onChange}
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input
            label={t('padding_top')}
            type='range'
            min={0}
            max={500}
            value={paddingTop}
            name='paddingTop'
            // eslint-disable-next-line no-param-reassign
            onChange={onChange}
          />
          <Form.Input
            label={t('padding_bottom')}
            type='range'
            min={0}
            max={500}
            value={paddingBottom}
            name='paddingBottom'
            // eslint-disable-next-line no-param-reassign
            onChange={onChange}
          />
        </Form.Group>
        <Form.Input
          label={t('column_width')}
          type='range'
          disabled
          min={1}
          max={100}
          value={size}
          name='size'
          // eslint-disable-next-line no-param-reassign
          onChange={onChange}
        />
      </Form>
    </div>
  );
};

export const TableCellDefaultProps = {
  children: null,
  paddingLeft: '10',
  paddingRight: '10',
  paddingTop: '10',
  paddingBottom: '10',
  size: '10',
  elementProps: false
};

TableCell.defaultProps = TableCellDefaultProps;

TableCell.propTypes = {
  children: PropTypes.node,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  isBorder: PropTypes.bool,
  size: PropTypes.string
};

TableCell.craft = {
  props: TableCellDefaultProps,
  related: { settings: TableCellSettings }
};
