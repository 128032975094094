import React from 'react';
import PropTypes from 'prop-types';
import { flatten, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import ViewList from './ViewList/ViewList';
import DetailsHeader from './DetailsHeader/DetailsHeader';
import { HoverPopup } from '../Popup/Popup';

const Details = ({
  fields,
  details,
  setEditMode,
  noIcons,
  activationHandler,
  useActivation,
  useCopy,
  copyHandler
}) => {
  const { t } = useTranslation();

  return (
    <>
      <DetailsHeader
        left={t('details_title')}
        right={
          !noIcons ? (
            <>
              {useCopy && (
                <HoverPopup content={t('copy_tooltip')}>
                  <Icon
                    name='copy outline'
                    onClick={() => copyHandler(details)}
                    disabled={!details}
                  />
                </HoverPopup>
              )}
              {useActivation && (
                <HoverPopup
                  content={
                    get(details, 'active')
                      ? t('deactivate_tooltip')
                      : t('activate_tooltip')
                  }
                >
                  <Icon
                    name={`toggle ${get(details, 'active') ? 'off' : 'on'}`}
                    disabled
                    onClick={activationHandler}
                  />
                </HoverPopup>
              )}
              <HoverPopup content={t('edit_tooltip')}>
                <Icon
                  name='edit'
                  disabled={!details}
                  onClick={setEditMode('edit')}
                />
              </HoverPopup>
            </>
          ) : null
        }
      />
      <ViewList fields={flatten(fields)} details={details} />
    </>
  );
};

Details.defaultProps = {
  useCopy: false,
  fields: [],
  setEditMode: () => {},
  noIcons: false,
  activationHandler: () => {},
  copyHandler: () => {},
  useActivation: false,
  details: null
};

Details.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)])
  ),
  details: PropTypes.shape({}),
  setEditMode: PropTypes.func,
  noIcons: PropTypes.bool,
  useCopy: PropTypes.bool,
  copyHandler: PropTypes.func,
  activationHandler: PropTypes.func,
  useActivation: PropTypes.bool
};

export default Details;
