import React from 'react';
import PropTypes from 'prop-types';
import { useEditor } from '@craftjs/core';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown } from 'semantic-ui-react';
import styles from './TopButtons.module.css';
import { useHistory } from 'react-router';

function TopButtons({
  contentRef,
  onSave,
  onBack,
  previewDoc,
  setShowPreview
}) {
  const { canUndo, actions, query } = useEditor((state, query) => ({
    canUndo: query.history.canUndo(),
    canRedo: query.history.canRedo()
  }));

  const { t } = useTranslation();
  const history = useHistory();

  const onSaveTemplate = () => {
    onSave(query.serialize(), contentRef.current?.innerHTML);
  };
  const handlePreview = async () => {
    if (!previewDoc) {
      await onSave(query.serialize(), contentRef.current.innerHTML);
    }
    setShowPreview(true);
  };

  const onReset = () => {
    history.go(0);
  };

  const onUndo = () => {
    actions.history.undo();
  };

  return (
    <>
      <div className={styles.topButton}>
        <Button.Group color='teal' style={{ marginRight: '1rem' }}>
          <Button positive size='medium' onClick={onSaveTemplate}>
            {t('save_button')}
          </Button>
          <Dropdown className='button icon' floating trigger={<></>}>
            <Dropdown.Menu>
              <Dropdown.Item text={t('save_button_reset')} onClick={onReset} />
              <Dropdown.Item
                text={t('save_button_undo')}
                onClick={onUndo}
                disabled={!canUndo}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Button.Group>

        <Button
          size='medium'
          className={styles.preview}
          onClick={handlePreview}
        >
          {t('preview_button')}
        </Button>
        <Button
          floated='right'
          size='medium'
          onClick={onBack}
          className={styles.back}
        >
          {t('back_button')}
        </Button>
      </div>
    </>
  );
}

TopButtons.defaultProps = { contentRef: null };

TopButtons.propTypes = {
  contentRef: PropTypes.shape({
    current: PropTypes.shape({ innerHTML: PropTypes.string })
  }),
  onSave: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
};

export default TopButtons;
