import React from 'react';
import {
  Input,
  Checkbox,
  Menu,
  Header,
  Container,
  Icon
} from 'semantic-ui-react';
import { get, update, set, remove, concat } from 'lodash';
import styles from './DemoDataForm.module.scss';

const DemoDataForm = ({ demoData, setDemoData, documentJson }) => {
  const isObject = a => a != null && a.constructor === Object;
  const isArray = a => Array.isArray(a);
  const isNumber = a => !isNaN(a);
  const isString = a => typeof a === 'string';
  const isBool = a => typeof a === 'boolean';
  const cloneObject = a => {
    return a ? JSON.parse(JSON.stringify(a)) : null;
  };

  const getFields = data => {
    let isObj = isObject(data);
    let fields = Object.keys(isObj ? data : {});
    return fields;
  };

  const handleOnChange = (value, path) => {
    setDemoData(prevState => {
      let oldState = cloneObject(prevState);
      let newState = update(oldState, path, n => {
        n = value;
        return n;
      });

      return newState;
    });
  };

  const addItemList = fieldPath => {
    setDemoData(prevState => {
      let oldState = cloneObject(prevState);
      let newState = update(oldState, fieldPath, () => {
        let currentFieldState = get(oldState, fieldPath);
        let initList = get(cloneObject(documentJson), fieldPath);
        let newArrState = concat(currentFieldState, [...initList]);

        return newArrState;
      });
      return newState;
    });
  };

  const deleteItem = (path, index) => {
    setDemoData(prevState => {
      let oldState = cloneObject(prevState);
      let myArr = get(oldState, path);
      let updatedArr = remove(myArr, (v, i) => {
        return i !== index;
      });
      let newState = set(oldState, path, updatedArr);
      return newState;
    });
  };

  const getComponentByType = (value, fieldName) => {
    if (isObject(value)) {
      return (
        <Container fluid>
          <Menu compact vertical fluid size='small'>
            {getComponents(value, fieldName + '.')}
          </Menu>
        </Container>
      );
    } else if (isArray(value)) {
      return (
        <Container className={styles.listContainer}>
          <Menu compact vertical>
            {value.map((item, i) => (
              <Menu.Item key={i} className={styles.listItem}>
                <Menu className={styles.itemMenu} floated>
                  <Icon
                    disabled
                    className={styles.minusIcon}
                    name='minus circle'
                    onClick={() => deleteItem(fieldName, i)}
                  />

                  {getComponents(item, fieldName + `[${i}].`)}
                </Menu>
              </Menu.Item>
            ))}
          </Menu>
        </Container>
      );
    } else if (isBool(value)) {
      return (
        <Checkbox
          disabled
          checked={value}
          onChange={(_, d) => handleOnChange(d.checked, fieldName)}
        />
      );
    } else if (isNumber(value) || isString(value)) {
      return (
        <Input
          className={styles.inputComponent}
          value={value ? value : ''}
          // disabled={fieldName === 'documentType'}
          disabled
          onChange={(_, d) => handleOnChange(d.value, fieldName)}
        />
      );
    } else {
      return <div>{fieldName} field has inappropriate value</div>;
    }
  };

  const getComponents = (data, fullFieldName = '') => {
    let providedData = cloneObject(data);
    let fields = getFields(providedData);
    return fields.map((field, i) => {
      let currentField = fullFieldName + field;
      let currentValue = get(providedData, field);
      return (
        <Menu.Item key={i}>
          <Header as='h5' className={styles.itemHeader}>
            {field}
            {isArray(currentValue) && (
              <Icon
                name='plus square outline'
                className={styles.plusIcon}
                onClick={() => addItemList(currentField)}
              />
            )}
          </Header>
          {getComponentByType(currentValue, currentField)}
        </Menu.Item>
      );
    });
  };

  return (
    <Menu fluid vertical size='small'>
      {getComponents(demoData)}
    </Menu>
  );
};

export default DemoDataForm;
