import React, { useEffect, useRef, useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useNode, useEditor } from '@craftjs/core';
import styles from './RenderNode.module.css';
import { Icon } from 'semantic-ui-react';

const RenderNode = ({ render }) => {
  const { query } = useEditor();
  const {
    isActive,
    isHover,
    dom,
    name,
    moveable,
    connectors: { drag }
  } = useNode(node => ({
    isActive: node.events.selected,
    isHover: node.events.hovered,
    dom: node.dom,
    name: node.data.custom.displayName || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    props: node.data.props
  }));

  const currentRef = useRef();
  const pageCounter = useRef();

  const craftDom = document.getElementsByClassName('craft')[0];
  const templateDom = document.getElementsByClassName('template')[0];
  const [pageNum, setPageNum] = useState(1);
  const [leftPosition, setLeftPosition] = useState(100);

  useEffect(() => {
    if (dom) {
      let hoverStyle
      if(dom.localName ==='button'){
        hoverStyle = `${styles.buttonSelected}`
      } else {
        hoverStyle = `${styles.componentSelected}`
      }
      
      if (isActive || isHover)dom.classList.add(hoverStyle)
      else dom.classList.remove(hoverStyle);
    }
  }, [dom, isActive, isHover]);

  const getPos = useCallback(dom2 => {
    const { top, left, bottom, right } = dom2
      ? dom2.getBoundingClientRect()
      : { top: 0, left: 0, bottom: 0 };
    return {
      topNum: top,
      top: `${top > 0 ? top - 2 : bottom}px`,
      left: `${left}px`,
      bottom: `${bottom}px`,
      right: `${right}px`
    };
  }, []);

  const scroll = useCallback(() => {
    const { current: currentDOM } = currentRef;
    const { current: counterDOM } = pageCounter;
    const pageWidth = templateDom && templateDom.offsetWidth;
    const onePageHeight = pageWidth * 1.4;

    setPageNum(
      Math.ceil(
        (craftDom.scrollTop - 256 + craftDom.clientHeight) / onePageHeight
      )
    );

    if (craftDom && counterDOM) {
      const { topNum } = getPos(craftDom);
      counterDOM.style.top = `${topNum + craftDom.clientHeight / 2}px`;
    }

    if (!currentDOM) return;
    const { top, left } = getPos(dom);
    currentDOM.style.top = top;
    currentDOM.style.left = left;
  }, [dom, getPos, craftDom, templateDom]);

  useEffect(() => {
    document.querySelector('.craft').addEventListener('scroll', scroll);
    return () => {
      document.querySelector('.craft').removeEventListener('scroll', scroll);
    };
  }, [scroll]);

  useEffect(() => {
    setLeftPosition(2);
    let timer = setTimeout(() => setLeftPosition(100), 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [pageNum]);

  return (
    <>
      {isHover || isActive
        ? ReactDOM.createPortal(
            <div
              ref={currentRef}
              className={styles.indicatorDiv}
              style={{
                left: getPos(dom).left,
                top: getPos(dom).top,
                zIndex:2
              }}
            >
              <div className={styles.nameDiv}>{name}</div>
              {moveable ? (
                <div className={styles.cursorMove} ref={drag}>
                  <Icon name='arrows alternate' />
                </div>
              ) : null}
            </div>,
            document.body
          )
        : null}

      {ReactDOM.createPortal(
        <div
          ref={pageCounter}
          className={styles.pageIndicator}
          style={{
            left: `calc(${getPos(craftDom).right} - ${leftPosition}rem)`,
            top: getPos(craftDom).top,
            zIndex: 9999
          }}
        >
          {pageNum}
        </div>,
        document.body
      )}

      {render}
    </>
  );
};

RenderNode.propTypes = {
  render: PropTypes.element.isRequired
};

export default RenderNode;
