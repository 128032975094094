import React, { useState, useEffect } from 'react';
import { useNode } from '@craftjs/core';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import '../../../../node_modules/react-quill/dist/quill.bubble.css';
import { Form, Dropdown } from 'semantic-ui-react';
import styles from './TextContent.module.scss';

export const TextContent = ({ text, fontSize = 14, display }) => {
  const {
    connectors: { connect, drag },
    selected,
    actions: { setProp }
  } = useNode(state => ({
    selected: state.events.selected
  }));

  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (!selected) setEditable(false);
  }, [selected]);

  const handleClick = () => {
    if (selected) setEditable(true);
  };

  const onChange = content => {
    setProp(props => {
      props.text = content;
    }, 500); // eslint-disable-line no-param-reassign
  };

  const toolbarOptions = [
    [{ header: [1, 2, false] }],
    [{ font: [] }],
    [{ size: [] }],
    [{ color: [] }, { background: [] }],
    ['bold', 'italic', 'underline'],
    [{ align: [] }]
  ];

  return (
    <div
      ref={ref => connect(drag(ref))}
      onClick={handleClick}
      onKeyDown={handleClick}
      role='presentation'
    >
      <ReactQuill
        readOnly={!editable}
        theme='bubble'
        className={styles.qltooltip}
        value={text}
        onChange={onChange}
        modules={{
          toolbar: toolbarOptions
        }}
      />
    </div>
  );
};

export const TextSettings = () => {
  const {
    actions: { setProp },
    display
  } = useNode(node => ({
    props: node.data.props,
    display: node.data.props.display
  }));

  const onChange = (_, data) => {
    setProp(props => {
      props.display = data.value;
    }, 500);
  };

  return (
    <div>
      <Form>
        <Form.Field>
          <Form.Input
            label='Display: '
            control={Dropdown}
            onChange={onChange}
            inline
            value={display}
            options={[
              {
                key: 'inline-block',
                value: 'inline-block',
                text: 'inline-block'
              },
              { key: 'block', value: 'block', text: 'block' },
              { key: 'inline', value: 'inline', text: 'inline' }
            ]}
          />
        </Form.Field>
      </Form>
    </div>
  );
};

export const TextDefaultProps = {
  text: 'Text goes here...',
  display: 'inline-block'
};

TextContent.defaultProps = TextDefaultProps;

TextContent.propTypes = { text: PropTypes.string, display: PropTypes.string };

TextContent.craft = {
  props: TextDefaultProps,
  related: { settings: TextSettings }
};
