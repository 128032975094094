import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Checkbox,
  Grid,
  Form,
  TextArea
} from 'semantic-ui-react';
import {
  isArray,
  isBoolean,
  isNumber,
  isDate,
  isObject,
  isString,
  isNull,
  get
} from 'lodash';
import { useTranslation } from 'react-i18next';

const PlaceholderModal = ({
  documentJson,
  showModal,
  setShowModal,
  changeTextFunc,
  currentValue = '',
  currentFormula,
  onCalcSave = () => {},
  setProp
}) => {
  const [checkedField, setCheckedField] = useState();
  const [parent, setParent] = useState();
  const [calcFormula, setCalcFormula] = useState(
    currentFormula ? currentFormula : ''
  );

  const { t } = useTranslation();

  useEffect(() => {
    get(showModal, 'action') === 'calc' || get(showModal, 'action') === 'sum'
      ? setCheckedField([])
      : setCheckedField(currentValue);
  }, [showModal, currentValue]);

  const checkDocType = (providedDocument, propKey, modalShowState) => {
    let currentField = get(providedDocument, propKey);
    if (isArray(currentField) && propKey === modalShowState.listSource) {
      return (
        <div>
          <div>{`This is List: ${propKey}`}</div>
          {getDocumentFields(currentField[0], {
            ...modalShowState,
            parent: '',
            showRootFields: true
          })}
        </div>
      );
    } else if (
      isObject(currentField) &&
      !isArray(currentField) &&
      modalShowState.showRootFields
    ) {
      let validPropKey = isNaN(propKey) ? propKey : `[${propKey}]`;

      let addParent = isNaN(propKey)
        ? modalShowState.parent + '.'
        : modalShowState.parent;

      return (
        <div style={{ margin: '10px' }}>
          <div>{`This is object: ${
            modalShowState.parent ? addParent : ''
          }${validPropKey}`}</div>
          {getDocumentFields(currentField, {
            ...modalShowState,
            parent: `${modalShowState.parent ? addParent : ''}${validPropKey}`,
            showRootFields: true
          })}
        </div>
      );
    } else if (
      isArray(currentField) &&
      modalShowState.parent !== '' &&
      modalShowState.showRootFields
    ) {
      return (
        <div style={{ margin: '10px' }}>
          {getDocumentFields(currentField, {
            ...modalShowState,
            parent: `${
              modalShowState.parent ? modalShowState.parent + '.' : ''
            }${propKey}`,
            showRootFields: true
          })}
        </div>
      );
    } else if (
      (isString(currentField) ||
        isNumber(currentField) ||
        isDate(currentField) ||
        isNull(currentField) ||
        isBoolean(currentField)) &&
      modalShowState.showRootFields
    ) {
      let thisFieldName =
        modalShowState.listSource && modalShowState.parent
          ? 'item.' + `${modalShowState.parent}.` + propKey // eslint-disable-line
          : modalShowState.listSource
          ? 'item.' + propKey // eslint-disable-line
          : modalShowState.parent
          ? modalShowState.parent + '.' + propKey // eslint-disable-line
          : propKey; // eslint-disable-line

      return (
        <Checkbox
          label={propKey}
          name={thisFieldName}
          parent={modalShowState.parent}
          checked={checkedField === thisFieldName}
          onClick={changeSelected}
        />
      );
    } else return <div />;
  };

  const changeSelected = (_, data) => {
    let newValue = data.name === checkedField ? '' : data.name;
    setCheckedField(newValue);
    setParent(data.parent);
    if (get(showModal, 'action') === 'calc') {
      setCalcFormula((prevState) => `${prevState} ${newValue}`);
    }
    if (get(showModal, 'action') === 'sum') {
      let nameArr = get(data, 'name').split('.');
      let newValue = nameArr[1];
      setCalcFormula((prevState) => `${prevState} ${newValue}`);
    }
  };

  const onOkClicked = () => {
    switch (get(showModal, 'action')) {
      case 'calc':
        changeTextFunc(calcFormula);
        onCalcSave(calcFormula);
        setCheckedField('');
        break;
      case 'source':
        changeTextFunc(checkedField);
        onCalcSave('');
        setCalcFormula('');
        //setting data-th-if for list
        if (/\[[^\]]*\]/.test(parent)) {
          setProp((props) => {
            props.ifAtt = parent.replace(/\[[^\]]*\]/g, '');
          });
        } else {
          setProp((props) => {
            props.ifAtt = '';
          });
        }
        break;
      case 'sum':
        changeTextFunc(calcFormula);
        onCalcSave(calcFormula);
        setCheckedField('');
        break;
      default:
    }

    setShowModal({
      show: false,
      listSource: '',
      parent: ''
    });
  };

  const onCancel = () => {
    switch (get(showModal, 'action')) {
      case 'calc':
        setCheckedField([]);
        setCalcFormula(currentFormula);
        break;
      case 'source':
        setCheckedField(currentValue);
        break;
      case 'sum':
        let splitValue = currentValue.split('{').pop().split('}')[0];
        setCalcFormula(splitValue);
        setCheckedField([]);
        break;
      default:
    }
    setShowModal({
      show: false,
      listSource: '',
      parent: ''
    });
  };

  const onTextAriaChange = (event) => {
    setCalcFormula(event.target.value);
  };

  const onReset = () => {
    setCalcFormula('');
    setCheckedField([]);
  };

  const getDocumentFields = (providedDocument = {}, modalShowState) => {
    let objKeys = Object.keys(providedDocument);
    if (showModal.show) {
      if (
        !objKeys.includes(modalShowState.listSource) &&
        !modalShowState.showRootFields
      ) {
        return <div>{t('list_is_not_found')}</div>;
      }
      return objKeys.map((propKey, index) => {
        return (
          <div key={index}>
            {checkDocType(providedDocument, propKey, modalShowState)}
          </div>
        );
      });
    }
  };

  const getCalcFields = () => {
    return (
      <Form>
        <TextArea
          placeholder='Add calculation'
          value={calcFormula}
          onChange={onTextAriaChange}
        />
        <Button style={{ marginTop: '5px' }} name='clear' onClick={onReset}>
          {t('clear')}{' '}
        </Button>
      </Form>
    );
  };

  return (
    <Modal open={showModal.show} style={{ zIndex: '20000' }}>
      <Modal.Header>{t('modal_header')}</Modal.Header>
      <Modal.Content>
        {get(showModal, 'action') === 'calc' ||
        get(showModal, 'action') === 'sum' ? (
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column>
                {getDocumentFields(documentJson, showModal)}
              </Grid.Column>
              <Grid.Column>{getCalcFields()}</Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (
          getDocumentFields(documentJson, showModal)
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>{t('cancel')}</Button>
        <Button onClick={onOkClicked}>{t('ok')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PlaceholderModal;
