import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment/Segment';
import Templates from './components/Templates/Templates';
import Template from './components/Template/Template';
import MenuBar from './components/MenuBar/MenuBar';
import styles from './AppRouter.module.scss';

const AppRouter = () => (
  <Router>
    <MenuBar />
    <Segment className={styles.segment}>
      <Switch>
        <Route path='/templates/:id/editor/:lang' component={Template} />
        <Route path='/templates/:id/editor' component={Template} />
        <Route path='/templates/:id' component={Templates} />
        <Route path='/templates' component={Templates} />
        <Redirect to='/templates' />
      </Switch>
    </Segment>
  </Router>
);

export default AppRouter;
