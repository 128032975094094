import React from 'react';
import PropTypes from 'prop-types';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment/Segment';
import styles from './ColumnHeader.module.scss';

const ColumnHeader = ({ children, className = '' }) => (
  <Segment
    size="large"
    className={`${styles.header} ${className}`}
    basic
  >
    {children}
  </Segment>
);

ColumnHeader.defaultProps = { className: '' };

ColumnHeader.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string
};

export default ColumnHeader;
