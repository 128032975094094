import { useState, useEffect, useMemo } from 'react';
import { without, isEqual } from 'lodash';

function useCheckboxes(existingItems, backupFn = () => {}, preselected = []) {
  const [selected, setSelected] = useState([]);
  const [unselected, setUnselected] = useState([]);
  const noneAssigned = useMemo(() => existingItems
    .filter(item => !preselected.includes(item)), [existingItems, preselected]);

  useEffect(() => {
    backupFn({ selected, unselected });
  }, [selected, unselected]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkIfAllSelected = () => isEqual([...selected].sort(), [...noneAssigned].sort()) && unselected.length === 0;
  const checkIfSelected = item => selected.includes(item) || (preselected.includes(item) && !unselected.includes(item));
  const onCheck = item => () => {
    let newSelected = [...selected];
    let newUnselected = [...unselected];
    if (preselected.includes(item)) {
      unselected.includes(item) ? newUnselected = without(newUnselected, item) : newUnselected.push(item);
    } else {
      selected.includes(item) ? newSelected = without(newSelected, item) : newSelected.push(item);
    }
    setSelected(newSelected);
    setUnselected(newUnselected);
  };
  function onCheckAll() {
    if (checkIfAllSelected()) {
      setSelected([]);
      setUnselected(preselected);
    } else {
      setSelected(noneAssigned);
      setUnselected([]);
    }
  }

  return {
    selected, unselected, onCheck, onCheckAll, checkIfAllSelected, checkIfSelected
  };
}

export default useCheckboxes;
