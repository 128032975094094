import React from 'react';
import { Element } from '@craftjs/core';
import { Grid } from 'semantic-ui-react';
import { Container } from './User/Container';
import { GridCell } from './User/GridCell';
import { Button } from './User/Button';
import { Input } from './User/Input';
import { Placeholder } from './User/Placeholder';
import UserPrev from './UserPrev';
import { useTranslation } from 'react-i18next';
import { StandardTable } from './User/StandardTable';

const Toolbox = () => {
  const { t } = useTranslation();

  return (
    <Grid>
      <UserPrev
        name={t('container')}
        element={<Element canvas is={Container} />}
        iconName='object ungroup outline'
      />

      <UserPrev
        name={t('grid_cell')}
        element={<Element canvas is={GridCell} />}
        iconName='th'
      />
      {/* <UserPrev name='Text' element={<TextContent />} iconName='font' /> */}
      <UserPrev name={t('text')} element={<Input />} iconName='font' />

      <UserPrev
        name={t('placeholder')}
        element={<Element canvas is={Placeholder} />}
        iconName='puzzle piece'
      />
      <UserPrev
        name={t('table')}
        element={<Element canvas is={StandardTable} />}
        iconName='table'
      />
      <UserPrev
        name={t('button')}
        element={<Button size='small' />}
        iconName='hand point up'
      />
      {/* ------- TO DO ----------*/}
      {/* <UserPrev
        name={t('list')}
        element={<Element canvas is={CustomTable} />}
        iconName='list'
      /> */}
    </Grid>
  );
};

export default Toolbox;
