import React, { useEffect } from 'react';
import { Ref, Form, Radio, Checkbox, Input } from 'semantic-ui-react';
import { useNode } from '@craftjs/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const Button = ({ size, fluid, color, btnText }) => {
  const {
    connectors: { connect, drag },
    actions: { setCustom },
  } = useNode();
  const { t } = useTranslation();

  useEffect(() => {
    setCustom((props) => {
      props.displayName = t('button');
    });
  }, [setCustom, t]);

  const isSmall = size === 'small';

  return (
    <Ref innerRef={(ref) => connect(drag(ref))}>
      <button
        style={{
          margin: '5px',
          backgroundColor: '#2185d0',
          color: '#FFF',
          height: isSmall ? '36px' : '43px',
          padding: isSmall ? '10px 15px' : '13px 20px',
          fontSize: isSmall ? '0.9rem' : '1.14rem',
          border: 'none',
          borderRadius: '4px',
          width: fluid ? '98%' : '',
          textAlign: 'center',
        }}
      >
        {btnText === '' ? t('click_me') : btnText}
      </button>
    </Ref>
  );
};

export const ButtonSettings = () => {
  const {
    actions: { setProp },
    size,
    fluid,
    btnText,
  } = useNode(({ data: { props } }) => ({
    size: props.size,
    fluid: props.fluid,
    btnText: props.btnText,
  }));

  const { t } = useTranslation();

  // eslint-disable-next-line no-param-reassign
  const handleForm = (_, data) => {
    setProp((props) => {
      props[data.name] = data.value;
    });
  };

  const handleCheck = (_, data) =>
    setProp((props) => {
      props.fluid = data.checked;
    });

  return (
    <div>
      <Form size='small'>
        <Form.Field component='legend'>{t('size')}</Form.Field>
        <Form.Group inline>
          <Form.Field
            control={Radio}
            label={t('small')}
            name='size'
            value='small'
            checked={size === 'small'}
            onChange={handleForm}
          />
          <Form.Field
            control={Radio}
            label={t('large')}
            name='size'
            value='large'
            checked={size === 'large'}
            onChange={handleForm}
          />
        </Form.Group>
        <Form.Field component='legend'>{t('fluid_header')}</Form.Field>
        <Form.Group inline>
          <Form.Field
            control={Checkbox}
            label={t('fluid_btn')}
            name='fluid'
            checked={fluid}
            onChange={handleCheck}
          />
        </Form.Group>
        <Form.Field component='legend'>{t('btn_text')}</Form.Field>
        <Form.Group inline>
          <Form.Field
            control={Input}
            name='btnText'
            value={btnText}
            onChange={handleForm}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export const ButtonDefaultProps = {
  size: 'small',
  btnText: '',
  fluid: false,
};

Button.defaultProps = ButtonDefaultProps;

Button.propTypes = {
  size: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  btnText: PropTypes.string,
  fluid: PropTypes.bool,
};

Button.craft = {
  props: ButtonDefaultProps,
  related: {
    settings: ButtonSettings,
  },
};
