import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';
import BaloonPopup from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import styles from './Popup.module.scss';

export const PopupSimple = ({
  header, content, trigger, closeBtnName, className
}) => (
  <Modal
    className={`${styles.simple} ${className}`}
    trigger={trigger}
    header={header}
    content={content}
    actions={[closeBtnName]}
  />
);

PopupSimple.defaultProps = { className: '' };

PopupSimple.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  trigger: PropTypes.element.isRequired,
  className: PropTypes.string,
  closeBtnName: PropTypes.string.isRequired
};

export const Popup = ({
  open, size, header, content, buttons, className = '', scrolling = false
}) => (
  <Modal open={open} size={size} className={`${styles.popup} ${className}`}>
    <Modal.Header>{header}</Modal.Header>
    <Modal.Content scrolling={scrolling}>{content}</Modal.Content>
    <Modal.Actions>
      {buttons.map(buttonProps => <Button key={buttonProps.name} {...buttonProps} />)}
    </Modal.Actions>
  </Modal>
);

Popup.defaultProps = { className: '', scrolling: false, size: undefined };

Popup.propTypes = {
  open: PropTypes.bool.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
  scrolling: PropTypes.bool
};

export const HoverPopup = ({ content, children, ...restProps }) => (
  <BaloonPopup
    content={content}
    trigger={children}
    basic
    size="tiny"
    position="top left"
    {...restProps}
  />
);
HoverPopup.propTypes = {
  children: PropTypes.element.isRequired,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired
};
