import React, { useState } from 'react';
import Image from 'semantic-ui-react/dist/commonjs/elements/Image/Image';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Label from 'semantic-ui-react/dist/commonjs/elements/Label/Label';
import CraftImage from './User/CraftImage';
import styles from './Images.module.scss';
import { useTranslation } from 'react-i18next';

const testImages = [
  'https://cdn.eso.org/images/thumb300y/eso1907a.jpg',
  'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg',
  'https://images.unsplash.com/photo-1542840843-3349799cded6?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTF8fHNtYWxsJTIwc2l6ZXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80',
  'https://cdn.vox-cdn.com/thumbor/TmgXcq6_4URVd0YN0SotUf5WYeA=/1400x1400/filters:format(jpeg)/cdn.vox-cdn.com/uploads/chorus_asset/file/9556001/chicks.0.0.0.jpg',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbSnCRBtWHSqalL-KbRd61BXvBgVPOqdJ30Q&usqp=CAU',
  'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/small-white-dog-breeds-cover-1560293099.jpg?crop=0.71435546875xw:1xh;center,top&resize=640:*'
];

const Images = () => {
  const [imgs, setImgs] = useState(testImages);
  const onChange = async event => {
    const img = event.target.files[0];
    if (img) {
      const reader = new FileReader();
      reader.onload = e => {
        const newImgs = [...imgs];
        const indexOfImg = imgs.indexOf(e.target.result);
        if (indexOfImg < 0) {
          newImgs.unshift(e.target.result);
          setImgs(newImgs);
        }
      };
      reader.readAsDataURL(img);
    }
  };

  const { t } = useTranslation();
  return (
    <div className={styles.center}>
      <div className={styles.uploadFileWrap}>
        <Button
          primary // TODO: add translation
        >
          {t('upload_image')}
        </Button>
        <Label
          className={styles.uploadFileLabel}
          as='label'
          basic
          htmlFor='upload'
        >
          <input
            hidden
            id='upload'
            type='file'
            accept='image/*'
            onChange={onChange}
          />
        </Label>
      </div>
      <Image.Group size='small'>
        {imgs.map(src => (
          <CraftImage key={src} src={src} />
        ))}
      </Image.Group>
    </div>
  );
};

export default Images;
