import { get, flatten } from 'lodash';

// form validation check
export const isRequired = (fields, name) =>
  get(
    flatten(fields).find(({ propName }) => propName === name),
    'required',
    false
  );

// Extracting template detials data and serving them to inputs
export const getFieldData = (details, propName, propPath, joinOnArray) => {
  let fieldData = '';
  switch (propName) {
    case 'language':
    case 'languageId':
      fieldData = get(details, propPath, []).map(data => data[propName]);
      break;
    default:
      fieldData = get(details, propName, '');
  }
  return Array.isArray(fieldData) && joinOnArray
    ? fieldData.join(', ')
    : fieldData;
};

// Forming the state object for template details input change handling
export const getCurrentTemplateDetailsState = (details, fields) =>
  flatten(fields)
    .filter(({ inEditMode }) => inEditMode)
    .reduce((ttl, curr) => {
      const pName = !curr.realName ? curr.propName : curr.realName;
      return {
        ...ttl,
        [pName]: getFieldData(details, pName, curr.propPath)
      };
    }, {});

// Extracting input field value from the data fetched from server
export function extractTemplateItemData(data, item) {
  let templateItemData = '';
  if (Array.isArray(item)) {
    templateItemData = item.map(({ propName, realName }) => {
      const pName = !realName ? propName : realName;
      return data[pName];
    });
  } else {
    const pName = !item.realName ? item.propName : item.realName;
    templateItemData = data[pName];
  }
  return templateItemData;
}
