import React from 'react';
import { Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './MenuBar.module.scss';

const MenuBar = () => {
  const { t } = useTranslation();
  return (
    <Menu text size='huge' className={styles.menubar}>
      <Menu.Item
        className={styles.menuItem}
        activeClassName={styles.activeMenuItem}
        as={NavLink}
        to='/templates'
      >
        {t('templates_menu_item')}
      </Menu.Item>
    </Menu>
  );
};

export default MenuBar;
