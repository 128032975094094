/* eslint-disable no-unused-vars */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable import/order */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-param-reassign */
/* eslint-disable arrow-parens */
/* eslint-disable jsx-quotes */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { useNode, useEditor } from '@craftjs/core';
import { useTranslation } from 'react-i18next';
import { SketchPicker } from 'react-color';

export const StandardTableHeader = ({ children, background, elementProps }) => {
  const {
    connectors: { connect },
    actions: { setCustom },
    parent
  } = useNode(({ data }) => ({
    parent: data.parent
  }));

  const { t } = useTranslation();

  const { currentState } = useEditor((state) => ({
    currentState: state
  }));
  let isBorder = currentState?.nodes[parent]?.data?.props?.isBorder;
  let cellWidth =
    currentState?.nodes[parent]?.data?.props?.cellWidthArray[
      elementProps?.index
    ];

  useEffect(() => {
    setCustom((props) => {
      props.displayName = t('table_header');
    });
  }, [setCustom, t]);

  if (elementProps?.isHeader) {
    return (
      <th
        ref={(ref) => connect(ref)}
        style={{
          paddingLeft: '5px',
          paddingRight: '5px',
          paddingTop: '1px',
          paddingBottom: '1px',

          borderBottom: `${isBorder ? '1px solid #e0e1e2' : '1px solid #FFFF'}`,
          borderLeft: `${isBorder ? '1px solid #e0e1e2' : ''}`,
          overflow: 'auto' /* Add a scrollbar if the content overflows */,
          width: `${Number(cellWidth)}%`,
          backgroundColor: `${background}`,
          maxWidth: 'fix-content'
        }}
      >
        {children}
      </th>
    );
  } else {
    return (
      <td
        ref={(ref) => connect(ref)}
        style={{
          paddingLeft: '5px',
          paddingRight: '5px',
          paddingTop: '1px',
          paddingBottom: '1px',
          borderBottom: `${isBorder ? '1px solid #e0e1e2' : ''}`,
          borderLeft: `${isBorder ? '1px solid #e0e1e2' : ''}`,
          backgroundColor: `${background}`
        }}
      >
        {children}
      </td>
    );
  }
};

export const StandardTableHeaderSettings = () => {
  const {
    actions: { setProp },
    cellWidth,
    background,
    node
  } = useNode((node) => ({
    node: node,
    dom: node.dom,
    cellWidth: node.data.props.cellWidth,
    background: node.data.props.background
  }));

  const { t } = useTranslation();

  const onChange = (e) => {
    setProp((props) => {
      props[e.target.name] = e.target.value;
    }, 500);
  };

  const handleBackgroundColor = (color) => {
    setProp((props) => {
      props.background = color.hex;
    }, 500);
  };

  return (
    <div>
      <Form>
        <Form.Input
          label={`${t('column_width')} ${cellWidth}px`}
          type='range'
          min={10}
          max={500}
          value={cellWidth}
          name='cellWidth'
          // eslint-disable-next-line no-param-reassign
          onChange={onChange}
          disabled
        />
        <Form.Field
          label={t('background')}
          control={SketchPicker}
          color={background}
          // eslint-disable-next-line no-param-reassign
          onChangeComplete={handleBackgroundColor}
        />

        {/*----- napravi da radi samo za footer----- */}
        {/* <Form.Input
          label={t('colomn_num')}
          control={Input}
          type='number'
          name='colSpan'
          value={colSpan}
          onChange={onChange}
        /> */}
      </Form>
    </div>
  );
};

export const StandardTableHeaderDefaultProps = {
  children: null,
  background: '#e0e0e0'
};

StandardTableHeader.defaultProps = StandardTableHeaderDefaultProps;

StandardTableHeader.propTypes = {
  children: PropTypes.node,
  background: PropTypes.string
};

StandardTableHeader.craft = {
  props: StandardTableHeaderDefaultProps,
  related: { settings: StandardTableHeaderSettings },
  rules: {
    canMoveIn: (node, self, helper) => {
      return self.dom.localName === 'th'
        ? node[0].data.name !== 'Placeholder'
        : true;
    }
  }
};
