import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Ref } from 'semantic-ui-react';
import { SketchPicker } from 'react-color';
import { useNode } from '@craftjs/core';
import styles from './Container.module.scss';
import { useTranslation } from 'react-i18next';

export const Template = ({ background, padding, children }) => {
  const {
    connectors: { connect, drag },
    actions: { setCustom }
  } = useNode();

  const ref = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    setCustom((props) => {
      props.displayName = t('template');
    });
  }, [setCustom, t]);

  return (
    <Ref innerRef={ref}>
      <div
        ref={(ref) => connect(drag(ref))}
        className={`${styles.selected} template`}
        style={{
          background,
          padding: `${padding}px`,
          minHeight: '297mm',
          width: '210mm',
          margin: 'auto'
        }}
      >
        {children}
      </div>
    </Ref>
  );
};

export const TemplateSettings = () => {
  const {
    actions: { setProp },
    background,
    padding
  } = useNode(({ data: { props } }) => ({
    background: props.background,
    padding: props.padding,
    direction: props.direction
  }));

  // eslint-disable-next-line no-param-reassign
  const handleBackgroundColor = (color) => {
    setProp((props) => {
      props.background = color.hex;
    }, 500);
  };
  const { t } = useTranslation();

  return (
    <div>
      <Form>
        <Form.Group widths='equal'>
          <Form.Input
            label={`${t('padding')}: ${padding}px`}
            type='range'
            min={0}
            max={500}
            value={padding}
            // eslint-disable-next-line no-param-reassign
            onChange={(value) => {
              setProp((props) => {
                props.padding = parseInt(value.target.value);
              }, 500);
            }}
          />
        </Form.Group>
        <Form.Field
          label={t('background')}
          control={SketchPicker}
          color={background}
          // eslint-disable-next-line no-param-reassign
          onChangeComplete={handleBackgroundColor}
        />
      </Form>
    </div>
  );
};

export const TemplateDefaultProps = {
  background: '#ffffff',
  padding: 0,
  children: null
};

Template.defaultProps = TemplateDefaultProps;

Template.propTypes = {
  background: PropTypes.string,
  padding: PropTypes.number,
  children: PropTypes.node
};

Template.craft = {
  displayName: 'Template',
  props: TemplateDefaultProps,
  related: {
    settings: TemplateSettings
  },
  rules: {
    canMoveIn: (node) => {
      return node[0].data.name !== 'GridCell';
    }
  }
};
