import { useState } from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

function useDataExtract(extractorFn, data, ...params) {
  const [extractedData, setExtractedData] = useState(extractorFn(data, ...params));

  useDeepCompareEffectNoCheck(() => {
    setExtractedData(extractorFn(data, ...params));
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return [extractedData, setExtractedData];
}

export default useDataExtract;
