import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { get, upperFirst } from 'lodash';
import useFetching from '../../hooks/Fetching/Fetching';
import { constructLangUrl } from '../../utils/editTempHelpers';
import Loading from '../Layout/Loading/Loading';
import useDataExtract from '../../hooks/DataExtract/DataExtract';
import CraftEditor from '../CraftEditor/CraftEditor';
import EditorHeader from '../CraftEditor/EditorHeader';
import styles from './Template.module.scss';
import useAttachmentsData from './useAttachmentsData';
import usePrevious from '../../hooks/Previous/Previous';
import { DocumentFieldsContextProvider } from './DocumentFieldsContext';
import {
  putByPathAndData,
  getByPathAndParams
} from '../../services/newServices';
import useToast from '../../hooks/Toast/Toast';
import { Modal, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { TEMPLATES_PATHS } from '../../common/network/ApiPaths';

const Template = ({ history, match }) => {
  const templateId = useRef(get(match, 'params.id', null));
  const [saveLoading, setSaveLoading] = useState(false);
  const sendToast = useToast();
  const [activeLang, setActiveLang] = useDataExtract(
    upperFirst,
    match.params.lang
  );

  const [templateDetails, loadingDetails] = useFetching(
    getByPathAndParams.bind(null, {
      path: TEMPLATES_PATHS.GET_BY_ID,
      pathVariables: { id: templateId.current }
    })
  );

  const { t } = useTranslation();

  const [textContext, setTextContext] = useState('');
  const [previewDoc, setPreviewDoc] = useState(undefined);
  const [showPreview, setShowPreview] = useState(false);
  const [sourceStructure, setSourceStructure] = useState({});
  // eslint-disable-next-line
  const [allAttachments, langAttachments, dispatch] = useAttachmentsData(
    get(templateDetails, 'attachments', []),
    activeLang
  );
  const prevLang = usePrevious(activeLang);

  useEffect(() => {
    if (templateDetails) {
      setTextContext(
        templateDetails && templateDetails.jsonData
          ? templateDetails.jsonData
          : ''
      );

      if (templateDetails?.templateSource?.structure) {
        let parsedStructure;
        try {
          parsedStructure = JSON.parse(
            templateDetails.templateSource.structure
          );
        } catch (error) {}
        setSourceStructure(parsedStructure);
      }
    }
  }, [templateDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const setLanguage = (event, { value: language }) => {
    setActiveLang(language);
    history.push(constructLangUrl(match, language));
  };

  const onBackClick = () => history.push('/templates');

  //SAVE Button
  const onSave = async (jsonData, htmlData) => {
    try {
      setSaveLoading(true);
      const updatedValues = {
        jsonData: jsonData,
        data:
          `<!DOCTYPE html><html xmlns:th="http://www.thymeleaf.org" lang="EN-US"><head><title>TEST TEMPLATE</title><link th:href="@{classpath:/semantic.min.css}" rel="stylesheet" type="text/css" /><link th:href="@{classpath:/paper.css}" rel="stylesheet" type="text/css" /> <link th:href="@{classpath:/custom.css}" rel="stylesheet" type="text/css" /></head><body>` +
          htmlData +
          `</body></html>`
      };

      const res = await putByPathAndData({
        path: TEMPLATES_PATHS.PATCH_BY_ID,
        pathVariables: { id: templateId.current },
        data: updatedValues,
        responseType: 'blob'
      });
      if (res?.data) {
        const fileURL = URL.createObjectURL(res.data);

        setPreviewDoc(fileURL);
        sendToast('created');
      }
    } catch (err) {
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    // For when user clickes [<-] on the browser while on an active language page
    if (prevLang && !activeLang) history.push('/templates');
  }, [activeLang]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Modal
        dimmer
        closeIcon
        open={showPreview}
        onClose={() => setShowPreview(false)}
        size='large'
      >
        <Modal.Header> {t('preview_title')}</Modal.Header>
        <Modal.Content image scrolling className={styles.contentModal}>
          <object
            className={styles.docPreviewContainer}
            data={previewDoc}
            type='application/pdf'
          >
            alt : ''
          </object>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setShowPreview(false)}>
            {t('close_button')}
          </Button>
        </Modal.Actions>
      </Modal>
      <div className={styles.top}>
        <div className={styles.title}>
          <EditorHeader
            templateName={get(templateDetails, 'name', '')}
            languages={[]}
            activeLang={activeLang}
            setActiveLang={setLanguage}
          />
        </div>
      </div>
      {loadingDetails ? (
        <Loading />
      ) : (
        <DocumentFieldsContextProvider documentJson={sourceStructure}>
          {saveLoading && <Loading />}
          <CraftEditor
            data={textContext}
            onSave={onSave}
            onBack={onBackClick}
            previewDoc={previewDoc}
            setShowPreview={setShowPreview}
            attachmentsData={{
              formType: get(templateDetails, 'formType'),
              langAttachments,
              attachmentsAction: dispatch
            }}
            previewData={sourceStructure}
          />
        </DocumentFieldsContextProvider>
      )}
    </>
  );
};

Template.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      lang: PropTypes.string
    })
  }).isRequired
};

export default Template;
