import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'semantic-ui-react';
import { useNode, Element } from '@craftjs/core';
import { TableCell } from './TableCell';
import TableModal from './TableModal';
import { DocumentFieldsContext } from '../../Template/DocumentFieldsContext';
import { useTranslation } from 'react-i18next';

export const CustomTable = ({
  background,
  paddingLeft,
  paddingRight,
  layout,
  height,
  listSrc,
  fontSize,
  fontStyle
}) => {
  const {
    connectors: { connect, drag },
    actions: { setCustom }
  } = useNode();

  const { t } = useTranslation();

  useEffect(() => {
    setCustom((props) => {
      props.displayName = t('list');
    });
  }, [setCustom, t]);

  const tableRowElements = [];

  for (let i = 0; i < layout; i++) {
    tableRowElements.push(
      <Element key={`item${i}`} id={`item${i}`} canvas is={TableCell} />
    );
  }

  return (
    <table
      ref={(ref) => connect(drag(ref))}
      data-th-with='total=0'
      style={{
        background,
        width: 'fillAvailable',
        minHeight: `${height}px`,
        paddingLeft: `${paddingLeft}px`,
        paddingRight: `${paddingRight}px`,
        fontSize,
        fontStyle
      }}
    >
      <tbody style={{ width: '100%' }}>
        <tr
          data-th-each={'orderItem: ${' + listSrc + '}'}
          style={{
            background,
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '100%'
          }}
        >
          {tableRowElements.map((v) => v)}
        </tr>
      </tbody>
    </table>
  );
};

export const CustomTableSettings = () => {
  const { documentJson } = useContext(DocumentFieldsContext);
  const [showModal, setShowModal] = useState(false);
  const {
    actions: { setProp },
    paddingLeft,
    paddingRight,
    layout,
    height,
    listSrc
  } = useNode((node) => ({
    background: node.data.props.background,
    paddingLeft: node.data.props.paddingLeft,
    paddingRight: node.data.props.paddingRight,
    height: node.data.props.height,
    layout: node.data.props.layout,
    listSrc: node.data.props.listSrc
  }));

  const { t } = useTranslation();

  // eslint-disable-next-line no-param-reassign
  const handleLayoutOption = (_, { value }) =>
    setProp((props) => {
      props.layout = value;
    });

  const changeText = (newValue) => {
    setProp((props) => {
      props.listSrc = newValue;
    }, 500);
  };

  return (
    <div>
      <Form>
        <TableModal
          currentValue={listSrc}
          documentJson={documentJson}
          showModal={showModal}
          setShowModal={setShowModal}
          changeTextFunc={changeText}
        />

        <Form.Field>
          <h5>{`${t('property_name')}: ${listSrc}`}</h5>
        </Form.Field>
        <Form.Field>
          <Button onClick={() => setShowModal(true)}>
            {t('select_source')}
          </Button>
        </Form.Field>

        <Form.Input
          label={t('colomn_num')}
          control={Input}
          type='number'
          inline
          value={layout}
          onChange={handleLayoutOption}
        />
        <Form.Input
          label={`${t('row_height')}: ${height}%`}
          type='range'
          min={60}
          max={300}
          value={height}
          // eslint-disable-next-line no-param-reassign
          onChange={(value) => {
            setProp((props) => {
              props.height = value.target.value;
            }, 500);
          }}
        />
        <Form.Input
          label={`${t('paddingLeft')}: ${paddingLeft}%`}
          type='range'
          min={60}
          max={300}
          value={paddingLeft}
          // eslint-disable-next-line no-param-reassign
          onChange={(value) => {
            setProp((props) => {
              props.height = value.target.value;
            }, 500);
          }}
        />
        <Form.Input
          label={`${t('paddingRight')}: ${paddingRight}%`}
          type='range'
          min={60}
          max={300}
          value={paddingRight}
          // eslint-disable-next-line no-param-reassign
          onChange={(value) => {
            setProp((props) => {
              props.height = value.target.value;
            }, 500);
          }}
        />
      </Form>
    </div>
  );
};

export const TableRowDefaultProps = {
  background: '#ffffff',
  paddingLeft: '10',
  paddingRight: '10',
  height: '60',
  layout: '3',
  listSrc: '',
  fontSize: 12,
  fontStyle: 'Helvetica, Arial, sans-serif'
};

CustomTable.defaultProps = TableRowDefaultProps;

CustomTable.propTypes = {
  background: PropTypes.string,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
  height: PropTypes.string,
  layout: PropTypes.string,
  listSrc: PropTypes.string
};

CustomTable.craft = {
  props: TableRowDefaultProps,
  related: {
    settings: CustomTableSettings
  }
};
