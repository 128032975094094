import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNode } from '@craftjs/core';
import styles from './Container.module.scss';
import { useTranslation } from 'react-i18next';
import { placeholderFields } from './util/elementConfig';
import FieldsRange from '../../Settings/FieldsRange';
import FieldSketch from '../../Settings/FieldSketch';

export const Container = ({
  background,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  marginBottom,
  marginTop,
  height,
  children
}) => {
  const {
    connectors: { connect, drag },
    actions: { setCustom }
  } = useNode();

  const { t } = useTranslation();

  useEffect(() => {
    setCustom((props) => {
      props.displayName = t('container');
    }, 500);
  }, [setCustom, t]);

  return (
    <>
      <div
        ref={(ref) => connect(drag(ref))}
        className={(styles.selected, styles.border)}
        style={{
          backgroundColor: background,
          paddingLeft: `${paddingLeft}px`,
          paddingRight: `${paddingRight}px`,
          paddingTop: `${paddingTop}px`,
          paddingBottom: `${paddingBottom}px`,
          marginTop: `${marginTop}px`,
          marginBottom: `${marginBottom}px`,
          minHeight: `${height}px`,
          width: '100%',
          float: 'left'
        }}
      >
        {children}
      </div>
      {/* clearfix don't work */}
      <div style={{ clear: 'both', visibility: 'hidden', height: '1px' }}>
        " "
      </div>
    </>
  );
};

export const ContainerSettings = () => {
  const {
    actions: { setProp },
    background,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    marginTop,
    marginBottom,
    height
  } = useNode(({ data: { props } }) => ({
    background: props.background,
    paddingLeft: props.paddingLeft,
    paddingRight: props.paddingRight,
    paddingTop: props.paddingTop,
    paddingBottom: props.paddingBottom,
    marginTop: props.marginTop,
    marginBottom: props.marginBottom,
    height: props.height,
    direction: props.direction
  }));

  // eslint-disable-next-line no-param-reassign
  const { t } = useTranslation();

  const fieldValue = {
    paddingLeft: paddingLeft,
    paddingRight: paddingRight,
    paddingTop: paddingTop,
    paddingBottom: paddingBottom,
    height: height,
    marginTop: marginTop,
    marginBottom: marginBottom
  };

  const grouped = placeholderFields.filter((el) => el.group);

  return (
    <>
      <FieldsRange config={grouped} setProp={setProp} fieldValue={fieldValue} />
      <FieldSketch
        background={background}
        label={t('background')}
        setProp={setProp}
      />
    </>
  );
};

export const ContainerDefaultProps = {
  background: '#ffffff',
  paddingLeft: '10',
  paddingRight: '10',
  paddingTop: '10',
  paddingBottom: '10',
  marginTop: '10',
  marginBottom: '10',
  height: '100',
  children: null
};

Container.defaultProps = ContainerDefaultProps;

Container.propTypes = {
  background: PropTypes.string,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.node
};

Container.craft = {
  props: ContainerDefaultProps,
  related: {
    settings: ContainerSettings
  }
};
