import React, { useState } from 'react';
import { Modal, Button, Checkbox, Form } from 'semantic-ui-react';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';

const TableModal = ({
  showModal,
  setShowModal,
  currentValue,
  changeTextFunc,
  documentJson
}) => {
  const [checkedField, setCheckedField] = useState(
    currentValue ? currentValue : undefined
  );

  const getDocumentFields = (providedDocument = {}) => {
    let objKeys = Object.keys(providedDocument);
    return objKeys.map((propKey) => {
      if (isArray(providedDocument[propKey])) {
        return (
          <Form.Field key={propKey}>
            <Checkbox
              label={propKey}
              name={propKey}
              checked={checkedField === propKey}
              onClick={changeSelected}
            />
          </Form.Field>
        );
      } else {
        return <div key={propKey} />;
      }
    });
  };

  const changeSelected = (_, data) => {
    let newValue = data.name === checkedField ? '' : data.name;
    setCheckedField(newValue);
  };

  const onOkClicked = () => {
    changeTextFunc(checkedField);
    setShowModal(false);
  };
  const { t } = useTranslation();

  return (
    <Modal open={showModal}>
      <Modal.Header>{t('modal_header')}</Modal.Header>
      <Modal.Content>
        <Form>{getDocumentFields(documentJson)}</Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setShowModal(false)}> {t('cancel')}</Button>
        <Button onClick={onOkClicked}>{t('ok')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default TableModal;
