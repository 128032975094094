import React from 'react'
import { SketchPicker } from 'react-color';
import { Form, Icon, Button, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const TextStyle = ({
        textAlign,
        fontWeight,
        textDecoration,
        fontItalic,
        background,
        fontColor, 
        setProp,
        popupContent
    })=>{

    const { t } = useTranslation();

    const onAlignButton = (e, data) => {
        setProp(props => {
          props.textAlign = data.name;
        }, 500);
      };
    
      const onTextDecoration = (e, data) => {
        setProp(props => {
          props[data.name] = !props[data.name];
        }, 500);
      };
    
      // eslint-disable-next-line no-param-reassign
      const handleBackgroundColor = color => {
        setProp(props => {
          props.background = color.hex;
        }, 500);
      };
    
      const handleFontColor = color => {
        setProp(props => {
          props.fontColor = color.hex;
        }, 500);
      };



    return(
        <div>
 <Popup content={popupContent} disabled={popupContent ? false : true} trigger={
      <Button.Group style={{ margin: '1rem 0.25rem 2rem 0' }}>
        <Button
          icon
          onClick={onAlignButton}
          name='left'
          active={textAlign === 'left'}
        >
          <Icon name='align left' />
        </Button>
        <Button
          icon
          onClick={onAlignButton}
          name='center'
          active={textAlign === 'center'}
        >
          <Icon name='align center' />
        </Button>
        <Button
          icon
          onClick={onAlignButton}
          name='right'
          active={textAlign === 'right'}
        >
          <Icon name='align right' />
        </Button>
        <Button
          icon
          onClick={onAlignButton}
          name='justify'
          active={textAlign === 'justify'}
        >
          <Icon name='align justify' />
        </Button>
      </Button.Group> } />
      <Button.Group>
        <Button
          icon
          name='fontWeight'
          onClick={onTextDecoration}
          active={fontWeight}
        >
          <Icon name='bold' />
        </Button>
        <Button
          icon
          name='textDecoration'
          onClick={onTextDecoration}
          active={textDecoration}
        >
          <Icon name='underline' />
        </Button>
        <Button
          icon
          name='fontItalic'
          onClick={onTextDecoration}
          active={fontItalic}
        >
          <Icon name='italic' />
        </Button>
      </Button.Group>{' '}
      <Button.Group>
        <Popup
          basic
          trigger={
            <Button icon>
              <Icon name='font' />
            </Button>
          }
          on='click'
        >
          <Form.Field
            label='Background'
            control={SketchPicker}
            color={fontColor}
            // eslint-disable-next-line no-param-reassign
            onChangeComplete={handleFontColor}
          />
        </Popup>
        <Popup
          basic
          trigger={
            <Button
              icon
              name='fontWeight'
              // onClick={onTextDecoration}
              active={fontWeight}
            >
              <Icon name='paint brush' />
            </Button>
          }
          on='click'
        >
          <Form.Field
            label={t('background')}
            control={SketchPicker}
            color={background}
            // eslint-disable-next-line no-param-reassign
            onChangeComplete={handleBackgroundColor}
          />
        </Popup>
      </Button.Group> 
      </div>
    )
}
export default TextStyle