import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import ColumHeader from '../../ColumnHeader/ColumnHeader';
import styles from './DetailsHeader.module.scss';

const DetailsHeader = ({ left, right = null }) => (
  <ColumHeader className={styles.topBar}>
    <Grid columns="equal">
      <Grid.Column>{left}</Grid.Column>
      <Grid.Column textAlign="right">{right}</Grid.Column>
    </Grid>
  </ColumHeader>
);

DetailsHeader.defaultProps = { right: null };

DetailsHeader.propTypes = {
  left: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  right: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};

export default DetailsHeader;
