export default [
  {
    fieldNameKey: 'id_column',
    propName: 'id',
    inMainTable: true,
    inCreateMode: false,
    inEditMode: true,
    editDisabled: true,
    inputType: 'disabled'
  },
  [
    {
      fieldNameKey: 'form_name_column',
      propName: 'name',
      inMainTable: true,
      inCreateMode: true,
      inEditMode: true,
      inputType: 'text',
      required: true
    },
    {
      fieldNameKey: 'application_field',
      propName: 'applicationId',
      inCreateMode: true,
      inputType: 'select',
      options: [],
      required: true
    },
    {
      fieldNameKey: 'application_field',
      propName: 'templateSource.application.name',
      inMainTable: true,
      inEditMode: true,
      editDisabled: true,
      inputType: 'disabled'
    }
  ],
  [
    {
      fieldNameKey: 'form_type_column',
      propName: 'formTypeId',
      inCreateMode: true,
      inputType: 'select',
      options: [],
      required: true
    },
    {
      fieldNameKey: 'form_type_column',
      propName: 'formTypeDescription',
      inMainTable: false,
      inEditMode: true,
      editDisabled: true,
      inputType: 'disabled'
    },
    {
      fieldNameKey: 'source_name',
      propName: 'sourceId',
      inCreateMode: true,
      inputType: 'select',
      options: [],
      required: true
    },
    {
      fieldNameKey: 'source_name',
      propName: 'templateSource.name',
      inMainTable: true,
      inEditMode: true,
      editDisabled: true,
      inputType: 'disabled'
    }
  ],
  {
    fieldNameKey: 'description',
    propName: 'description',
    inMainTable: false,
    inCreateMode: true,
    inEditMode: true,
    inputType: 'textarea'
  }
];
