import React from 'react';
import PropTypes from 'prop-types';
import { useEditor } from '@craftjs/core';
import { Icon, Card, Header, Grid } from 'semantic-ui-react';
import styles from './UserPrev.module.scss';

export default function UserPrev(props) {
  const { connectors } = useEditor();
  const { element, name, iconName } = props;
  return (
    <Grid.Column width={5}>
      <div ref={ref => connectors.create(ref, element)}>
        <Card>
          <Card.Header textAlign='center' className={styles.cardHeader}>
            <Icon name={iconName} size='big' />
          </Card.Header>
          <Card.Content>
            <Card.Description className={styles.cardDescription}>
              <Header as='h4'>{name}</Header>
            </Card.Description>
          </Card.Content>
        </Card>
      </div>
    </Grid.Column>
  );
}

UserPrev.propTypes = {
  element: PropTypes.node.isRequired,
  iconName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};
