import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import DetailsForm from './DetailsForm/DetailsForm';
import DetailsHeader from './DetailsHeader/DetailsHeader';
import styles from './EditDetails.module.scss';

const EditDetails = ({
  header,
  panelState,
  fields,
  setFields,
  details,
  onCancelGoBack,
  onSave
}) => (
  <div className={styles.wrap}>
    <DetailsHeader
      left={header}
      right={
        <Icon name={panelState === 'edit' ? 'edit' : 'edit outline'} disabled />
      }
    />
    <DetailsForm
      panelState={panelState}
      fields={fields}
      setFields={setFields}
      onCancelGoBack={onCancelGoBack}
      details={details}
      onSave={onSave}
    />
  </div>
);

EditDetails.defaultProps = {
  header: '',
  details: null,
  panelState: ''
};

EditDetails.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)])
  ).isRequired,
  details: PropTypes.shape({}),
  onSave: PropTypes.func.isRequired,
  header: PropTypes.string,
  onCancelGoBack: PropTypes.func.isRequired,
  panelState: PropTypes.string
};

export default EditDetails;
