import React from 'react';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer/Dimmer';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader/Loader';

const Loading = () => (
  <Dimmer active inverted>
    <Loader size="large" />
  </Dimmer>
);

export default Loading;
