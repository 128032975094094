import React from 'react';
import { Frame, Element } from '@craftjs/core';
import { Template } from './User/Template';
import { useEditor } from '@craftjs/core';

const FrameEditor = ({ width, data }) => {
  const { connectors } = useEditor(state => ({
    enabled: state.options.enabled
  }));

  return (
    <div
      className='craftjs-renderer'
      style={{
        width: '100%',
        height: 'auto',
        paddingTop: '8rem',
        paddingBottom: '8rem',
        background: '#e0e0e0'
      }}
      ref={ref => connectors.select(connectors.hover(ref, null), null)}
    >
      <Frame key={'en'} data={data}>
        <Element canvas is={Template} />
      </Frame>
    </div>
  );
};

export default FrameEditor;
