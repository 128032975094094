import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@craftjs/core';
import { Layers } from '@craftjs/layers';
import { Grid, Ref, Table, Segment, Header } from 'semantic-ui-react';
import { Button } from './User/Button';
import { TextContent } from './User/TextContent';
import { Input } from './User/Input';
import { GridCell } from './User/GridCell';
import { GridRow } from './User/GridRow';
import { CustomTable } from './User/Table';
import { TableCell } from './User/TableCell';
import { Container } from './User/Container';
import { CanvasImage } from './User/CraftImage';
import { Placeholder } from './User/Placeholder';
import { StandardTable } from './User/StandardTable';
import { StandardTableHeader } from './User/StandardTableCells';
import { Template } from './User/Template';
import RenderNode from './RenderNode';
import TabPanel from './TabPanel';
import { useTranslation } from 'react-i18next';
import styles from './CraftEditor.module.scss';
import FrameEditor from './FrameEditor';

export default function CraftEditor({
  data,
  onSave,
  onBack,
  attachmentsData,
  previewDoc,
  setShowPreview
}) {
  const ref = useRef(null);

  const { t } = useTranslation();

  return (
    <div style={{ margin: '0 auto', width: '100%', height: '100%' }}>
      <Editor
        indicator={{
          success: '#a9fb08', // yellow
          error: '#e34850', // red
          thickness: 5,
          transition: 'width 1s'
        }}
        resolver={{
          Button,
          Container,
          TextContent,
          GridCell,
          GridRow,
          Input,
          Table,
          CanvasImage,
          CustomTable,
          TableCell,
          Placeholder,
          StandardTable,
          StandardTableHeader,
          Template
        }}
        onRender={RenderNode}
      >
        <Grid columns='two' divided className={styles.grid}>
          <Grid.Column className={`${styles.column} craft`}>
            <Ref innerRef={ref}>
              <FrameEditor data={data} />
            </Ref>
          </Grid.Column>

          <Grid.Column className={styles.tab}>
            <TabPanel
              attachmentsData={attachmentsData}
              ceRef={ref}
              onSave={onSave}
              onBack={onBack}
              previewDoc={previewDoc}
              setShowPreview={setShowPreview}
            />
            <Segment className={styles.segment}>
              <Header size='tiny'>{t('page_layout')}</Header>
              <Layers expandRootOnLoad={true} />
            </Segment>
          </Grid.Column>
        </Grid>
      </Editor>
    </div>
  );
}

CraftEditor.propTypes = {
  data: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  attachmentsData: PropTypes.shape({
    formType: PropTypes.string,
    langAttachments: PropTypes.shape({}),
    attachmentsAction: PropTypes.func
  }).isRequired
};
