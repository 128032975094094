import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment/Segment';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import { useTranslation } from 'react-i18next';
import TemplateFormItem from './TemplateFormItem';
import {
  getCurrentTemplateDetailsState,
  extractTemplateItemData
} from '../../../../utils/detailsHelpers';
import useDataExtract from '../../../../hooks/DataExtract/DataExtract';
import ShadowSeparator from '../../ShadowSeparator/ShadowSeparator';
import styles from './DetailsForm.module.scss';
import { getByPathAndParams } from '../../../../services/newServices';
import { EXTERNAL_APPLICATIONS } from '../../../../common/network/ApiPaths';

const DetailsForm = ({
  panelState,
  fields,
  setFields,
  onCancelGoBack,
  details,
  onSave
}) => {
  const [templateDetails, setTemplateDetails] = useDataExtract(
    getCurrentTemplateDetailsState,
    details,
    fields
  );

  const [sourcesLoading, setSourcesLoading] = useState('');

  const [inputsError, setInputsError] = useState({});

  const { t } = useTranslation();

  const templateFormItemChangeHandler = (_, data) => {
    setTemplateDetails(p => {
      return { ...p, [data.name]: data.value };
    });
  };

  useEffect(() => {
    const myFetch = async () => {
      try {
        setSourcesLoading('sourceId');

        let sourcesResponse = await getByPathAndParams({
          path: EXTERNAL_APPLICATIONS.SOURCES,
          params: { applicationId: templateDetails.applicationId }
        });

        setFields(p => {
          let sourcesOptions = sourcesResponse?.data ?? [];
          p[2][2].options = sourcesOptions.map(({ id, key, name }) => {
            return {
              id,
              key,
              value: id,
              text: name
            };
          });
          return p;
        });
      } catch (err) {
      } finally {
        setSourcesLoading('');
      }
    };
    myFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateDetails.applicationId]);

  useEffect(() => {
    // prevent browser validation
    document.querySelector('form').setAttribute('novalidate', true);

    const errors = { ...inputsError };
    Object.keys(errors).forEach(propName => {
      if (!isEmpty(templateDetails[propName])) delete errors[propName];
    });
    setInputsError(errors);
  }, [templateDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = () => {
    const errors = {};
    isEmpty(errors) ? onSave(templateDetails) : setInputsError(errors);
  };

  return (
    <div className={styles.editBox}>
      <Segment className={styles.inputs}>
        <Form>
          {fields.map(item => (
            <TemplateFormItem
              key={
                Array.isArray(item)
                  ? item.map(({ fieldNameKey }) => fieldNameKey).toString()
                  : item.fieldNameKey
              }
              panelState={panelState}
              onChangeHandler={templateFormItemChangeHandler}
              item={item}
              error={extractTemplateItemData(inputsError, item)}
              value={extractTemplateItemData(templateDetails, item)}
              itemLoading={sourcesLoading}
            />
          ))}
        </Form>
        <Segment basic clearing className={styles.buttons}>
          <ShadowSeparator />
          <Button.Group floated='left' size='huge'>
            <Button positive onClick={onSubmit}>
              {t('save_button')}
            </Button>
          </Button.Group>
          <Button floated='right' negative size='huge' onClick={onCancelGoBack}>
            {t('cancel_button')}
          </Button>
        </Segment>
      </Segment>
    </div>
  );
};

DetailsForm.defaultProps = { details: null };

DetailsForm.propTypes = {
  panelState: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)])
  ).isRequired,
  details: PropTypes.shape({}),
  onSave: PropTypes.func.isRequired,
  onCancelGoBack: PropTypes.func.isRequired
};

export default DetailsForm;
