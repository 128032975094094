import React from 'react';
import PropTypes from 'prop-types';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment/Segment';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header/Header';
import TableRow from './TableRow';
import styles from './TableBody.module.scss';

const TableBody = ({
  data, fields, handleRowClick, selectedItem,
  placeholder, checkboxes, useActivation, goTo
}) => (
  <Segment.Group className={styles.group}>
    {data && data.length
      ? data.map(item => (
        <TableRow
          key={item.id}
          item={item}
          fields={fields}
          handleRowClick={handleRowClick}
          selectedItem={selectedItem}
          checkboxes={checkboxes}
          useActivation={useActivation}
          goTo={goTo}
        />
      ))
      : (
        <Segment placeholder disabled>
          {Array.isArray(data) && <Header icon>{placeholder}</Header>}
        </Segment>
      )}
  </Segment.Group>
);

TableBody.defaultProps = {
  data: null,
  selectedItem: null,
  checkboxes: null,
  goTo: () => {}
};

TableBody.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  fields: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)])
  ).isRequired,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checkboxes: PropTypes.shape({
    onCheck: PropTypes.func,
    checkIfSelected: PropTypes.func
  }),
  handleRowClick: PropTypes.func.isRequired,
  useActivation: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  goTo: PropTypes.func
};

export default TableBody;
