/* eslint-disable jsx-quotes */
/* eslint-disable arrow-parens */
/* eslint-disable prefer-const */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/default-props-match-prop-types */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Checkbox } from 'semantic-ui-react';
import { useNode, Element } from '@craftjs/core';
import { TableCell } from './TableCell';
import { StandardTableHeader } from './StandardTableCells';
import TableModal from './TableModal';
import { DocumentFieldsContext } from '../../Template/DocumentFieldsContext';
import { useTranslation } from 'react-i18next';
import FieldsCheck from '../../Settings/FieldsCheck';
import settingCheckBox from './util/standarTableFields';

export const StandardTable = ({
  columnCount,
  footerColumn,
  rowCount,
  paddingLeft,
  paddingRight,
  height,
  color,
  listSrc,
  isBorder,
  isHeader,
  isFooter,
  isColumnGenerate,
  cellWidthArray
}) => {
  const {
    connectors: { connect, drag },
    actions: { setCustom },
    node
  } = useNode((node) => ({ node: node }));

  const body = [];
  const footer = [];

  const { t } = useTranslation();

  useEffect(() => {
    setCustom((props) => {
      props.displayName = t('table');
    });
  }, [setCustom, t]);

  const generateHeader = (n, count) => {
    const headerCells = [];
    for (let i = 0; i < count; i++) {
      headerCells.push(
        <Element
          key={`item${n}${i}`}
          id={`item${n}${i}`}
          elementProps={{ isHeader: true, index: i }}
          canvas
          is={StandardTableHeader}
        />
      );
    }
    return headerCells;
  };

  const generateFooter = (n, count) => {
    const headerCells = [];
    for (let i = 0; i < count; i++) {
      headerCells.push(
        <Element
          key={`item${n}${i}`}
          id={`item${n}${i}`}
          elementProps={{ isHeader: false }}
          canvas
          is={StandardTableHeader}
        />
      );
    }
    return headerCells;
  };

  const generateCell = (n, count) => {
    let bodyCells = [];
    for (let i = 0; i < count; i++) {
      bodyCells.push(
        <Element
          key={`item${n}${i}`}
          id={`item${n}${i}`}
          elementProps={i}
          isHeader={isHeader}
          canvas
          is={TableCell}
        />
      );
    }
    return bodyCells;
  };

  let bodyRow = isColumnGenerate ? 1 : rowCount;

  for (let i = 0; i < bodyRow; i++) {
    body.push(
      <tr
        key={i}
        style={{ height: `${height}px` }}
        data-th-each={
          listSrc && isColumnGenerate ? 'item: ${' + listSrc + '}' : null
        }
      >
        {generateCell(i, columnCount)}
      </tr>
    );
  }

  return (
    <div
      ref={(ref) => connect(drag(ref))}
      style={{
        width: '100%',
        padding: `1px`,
        paddingLeft: `${paddingLeft}px`,
        paddingRight: `${paddingRight}px`
      }}
    >
      <table
        style={{
          border: `${isBorder ? '1px solid #F6EDED' : ''}`,
          borderRadius: ' 4px',
          width: '100%',
          backgroundColor: { color },
          tableLayout: 'fixed',
          borderCollapse: 'collapse'
        }}
      >
        {isHeader && (
          <thead style={{ background: '#e0e0e0', height: `${height}px` }}>
            <tr style={{ minHeight: '30px' }}>
              {generateHeader(rowCount + 1, columnCount).map((el) => el)}
            </tr>
          </thead>
        )}
        <tbody>{body.map((el) => el)}</tbody>
        {/* footer */}
        {isFooter && (
          <tfoot style={{ background: '#e0e0e0', height: `${height}px` }}>
            <tr style={{ minHeight: '30px' }}>
              {generateFooter(rowCount + 2, columnCount).map((el) => el)}
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export const StandardTableSettings = () => {
  const { documentJson } = useContext(DocumentFieldsContext);
  const [showModal, setShowModal] = useState(false);
  const {
    actions: { setCustom, setProp },
    paddingLeft,
    paddingRight,
    height,
    columnCount,
    rowCount,
    footerColumn,
    listSrc,
    isBorder,
    isHeader,
    isFooter,
    isColumnGenerate,
    cellWidthArray
  } = useNode(({ data: { props } }) => ({
    background: props.background,
    paddingLeft: props.paddingLeft,
    paddingRight: props.paddingRight,
    height: props.height,
    layout: props.layout,
    columnCount: props.columnCount,
    rowCount: props.rowCount,
    inverted: props.inverted,
    color: props.color,
    footerColumn: props.footerColumn,
    listSrc: props.listSrc,
    isBorder: props.isBorder,
    isHeader: props.isHeader,
    isFooter: props.isFooter,
    isColumnGenerate: props.isColumnGenerate,
    cellWidthArray: props.cellWidthArray
  }));

  const { t } = useTranslation();

  const fieldValue = {
    isBorder: isBorder,
    isHeader: isHeader,
    isFooter: isFooter
  };

  const onChange = (e) => {
    setProp((props) => {
      props[e.target.name] = Number(e.target.value);
    }, 500);
  };
  const onChangeColumn = (e) => {
    let newCellArray = [...cellWidthArray];

    setProp((props) => {
      let newValue = Number(e.target.value);
      if (props.columnCount < newValue) {
        newCellArray.push(10);
      } else {
        newCellArray.pop();
      }
      props.columnCount = newValue;
      props.cellWidthArray = newCellArray;
    }, 500);
  };

  // TO DO -- I will use this fields in the future
  // eslint-disable-next-line no-param-reassign
  // const handleLayoutOption = (_, { value }) =>
  //   setProp(props => {
  //     props.layout = value;
  //   });
  // const onChangeColor = (_, data) => {
  //   setProp(props => {
  //     props.color = data.value;
  //   }, 500);
  // };

  // const changeInverted = () => {
  //   setProp(props => {
  //     props.inverted = !inverted;
  //   }, 500);
  // };

  const changeText = (newValue) => {
    setProp((props) => {
      props.listSrc = newValue;
    }, 500);
  };

  const onChecked = (checked, fieldName) =>
    setProp((props) => {
      props[fieldName] = checked;
    });

  return (
    <div>
      <Form>
        <TableModal
          currentValue={listSrc}
          documentJson={documentJson}
          showModal={showModal}
          setShowModal={setShowModal}
          changeTextFunc={changeText}
        />
        <Form.Field>
          <h5>{`${t('property_name')}: ${listSrc}`}</h5>
        </Form.Field>
        <Form.Field>
          <Button onClick={() => setShowModal(true)}>
            {t('select_source')}
          </Button>
        </Form.Field>
        <Form.Field
          key={isColumnGenerate}
          control={Checkbox}
          label={t('is_row_generate')}
          checked={isColumnGenerate}
          onChange={(_, d) => onChecked(d.checked, 'isColumnGenerate')}
        />
        <Form.Group widths='equal'>
          <Form.Input
            label={t('colomn_num')}
            control={Input}
            type='number'
            name='columnCount'
            value={columnCount}
            onChange={onChangeColumn}
          />
          <Form.Input
            label={t('row_number')}
            control={Input}
            disabled={isColumnGenerate}
            type='number'
            name='rowCount'
            value={rowCount}
            onChange={onChange}
          />
        </Form.Group>
        {cellWidthArray.map((cellWidth, index) => (
          <Form.Input
            label={`${t('cell_width')} ${index + 1}: ${cellWidth}%`}
            type='range'
            min={10}
            max={100}
            value={cellWidth}
            // eslint-disable-next-line no-param-reassign
            onChange={(value) => {
              setProp((props) => {
                props.cellWidthArray[index] = value.target.value;
              }, 500);
            }}
          />
        ))}
        <Form.Input
          label={`${t('paddingLeft')}: ${paddingLeft}px`}
          type='range'
          min={0}
          max={500}
          value={paddingLeft}
          // eslint-disable-next-line no-param-reassign
          onChange={(value) => {
            setProp((props) => {
              props.paddingLeft = value.target.value;
            }, 500);
          }}
        />
        <Form.Input
          label={`${t('paddingRight')}: ${paddingRight}px`}
          type='range'
          min={0}
          max={500}
          value={paddingRight}
          // eslint-disable-next-line no-param-reassign
          onChange={(value) => {
            setProp((props) => {
              props.paddingRight = value.target.value;
            }, 500);
          }}
        />

        <Form.Input
          label={`${t('row_height')}: ${height}px`}
          type='range'
          min={20}
          max={150}
          value={height}
          // eslint-disable-next-line no-param-reassign
          onChange={(value) => {
            setProp((props) => {
              props.height = value.target.value;
            }, 500);
          }}
        />
      </Form>
      <FieldsCheck
        config={settingCheckBox}
        setProp={setProp}
        fieldValue={fieldValue}
        inline={true}
        label={''}
      />
    </div>
  );
};

export const StandardTableDefaultProps = {
  background: '#ffffff',
  paddingLeft: '10',
  paddingRight: '10',
  height: '30',
  layout: '3',
  listSrc: '',
  fontSize: 12,
  fontStyle: 'Helvetica, Arial, sans-serif',
  columnCount: 3,
  rowCount: 1,
  singleLine: false,
  striped: false,
  inverted: false,
  color: 'no color',
  footerColumn: 3,
  isBorder: true,
  isHeader: true,
  isFooter: true,
  isColumnGenerate: true,
  cellWidthArray: [33, 33, 33]
};

StandardTable.defaultProps = StandardTableDefaultProps;

StandardTable.propTypes = {
  background: PropTypes.string,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
  height: PropTypes.string,
  layout: PropTypes.string,
  listSrc: PropTypes.string,
  columnCount: PropTypes.number,
  rowCount: PropTypes.number,
  inverted: PropTypes.bool,
  color: PropTypes.string,
  footerColumn: PropTypes.number,
  isBorder: PropTypes.bool,
  isHeader: PropTypes.bool,
  isFooter: PropTypes.bool,
  isColumnGenerate: PropTypes.bool,
  cellWidthArray: PropTypes.array
};

StandardTable.craft = {
  props: StandardTableDefaultProps,
  related: {
    settings: StandardTableSettings
  }
};
