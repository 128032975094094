import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Loading from './Loading/Loading';
import styles from './Layout.module.scss';
import '../../semantic.css';
import 'react-semantic-toasts/styles/react-semantic-alert.css';

const Layout = ({
  loading = [false, false, false], left = null, middle = null, right = null, top = null
}) => (
  <Grid className={styles.grid}>
    {loading[0] && <Loading />}
    <Grid.Column width={2} className={styles.left}>{left}</Grid.Column>
    {top ? (
      <Grid.Column width={14}>
        <Grid>
          {loading[1] && !loading[0] && <Loading />}
          <Grid.Row className="top-row">
            <Grid.Column className="top-column">
              {top}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className="middle-with-top">
              {middle}
            </Grid.Column>
            <Grid.Column className="right-with-top">
              {right}
              {(loading[2] && !loading[0] && !loading[1]) && <Loading />}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    ) : (
      <>
        {loading[1] && !loading[0] && <Loading />}
        <Grid.Column width={9}>
          {middle}
        </Grid.Column>
        <Grid.Column width={5}>
          {right}
          {(loading[2] && !loading[0] && !loading[1]) && <Loading />}
        </Grid.Column>
      </>
    )}
  </Grid>
);

Layout.defaultProps = {
  loading: [false, false, false],
  left: null,
  middle: null,
  right: null,
  top: null
};

Layout.propTypes = {
  loading: PropTypes.arrayOf(PropTypes.bool),
  left: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  middle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  right: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  top: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};

export default Layout;
