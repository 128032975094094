import React from 'react';
import PropTypes from 'prop-types';
import { Form, Ref } from 'semantic-ui-react';
import { useNode } from '@craftjs/core';
import { useTranslation } from 'react-i18next';

export const GridRow = ({
  children,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  marginTop,
  marginLeft,
  marginRight,
  marginBottom
}) => {
  const {
    connectors: { connect, drag },
    actions: { setCustom }
  } = useNode();

  const { t } = useTranslation();

  setCustom(props => {
    props.displayName = t('grid');
  }, 500);

  return (
    <Ref innerRef={ref => connect(drag(ref))}>
      <>
        <div
          style={{
            width: '100%',
            minHeight: '50px',
            paddingLeft: `${paddingLeft}px`,
            paddingRight: `${paddingRight}px`,
            paddingTop: `${paddingTop}px`,
            paddingBottom: `${paddingBottom}px`,
            marginTop: `${marginTop}px`,
            marginLeft: `${marginLeft}px`,
            marginRight: `${marginRight}px`,
            marginBottom: `${marginBottom}px`,
            background: 'transparent',
            float: 'left'
          }}
        >
          {children}
        </div>
        {/* clearfix don't work */}
        <div style={{ clear: 'both', visibility: 'hidden', height: '1px' }}>
          " "
        </div>
      </>
    </Ref>
  );
};

export const GridRowSettings = () => {
  const {
    actions: { setProp },
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    marginTop,
    marginBottom
  } = useNode(({ data: { props } }) => ({
    paddingLeft: props.paddingLeft,
    paddingRight: props.paddingRight,
    paddingTop: props.paddingTop,
    paddingBottom: props.paddingBottom,
    marginTop: props.marginTop,
    marginBottom: props.marginBottom,
    borderColor: props.borderColor
  }));

  const onChange = e => {
    setProp(props => {
      props[e.target.name] = e.target.value;
    }, 500);
  };

  return (
    <div>
      <Form>
        <Form.Group widths='equal'>
          <Form.Input
            size='mini'
            label={`Padding left: ${paddingLeft}px`}
            type='range'
            min={0}
            max={500}
            value={paddingLeft}
            name='paddingLeft'
            // eslint-disable-next-line no-param-reassign
            onChange={onChange}
          />
          <Form.Input
            size='mini'
            label={`Padding right: ${paddingRight}px`}
            type='range'
            min={0}
            max={500}
            value={paddingRight}
            name='paddingRight'
            // eslint-disable-next-line no-param-reassign
            onChange={onChange}
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input
            size='mini'
            label={`Padding top: ${paddingTop}px`}
            type='range'
            min={0}
            max={500}
            value={paddingTop}
            name='paddingTop'
            // eslint-disable-next-line no-param-reassign
            onChange={onChange}
          />
          <Form.Input
            size='mini'
            label={`Padding bottom: ${paddingBottom}px`}
            type='range'
            min={0}
            max={500}
            value={paddingBottom}
            name='paddingBottom'
            // eslint-disable-next-line no-param-reassign
            onChange={onChange}
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input
            size='mini'
            label={`Margin top: ${marginTop}px`}
            type='range'
            min={0}
            max={500}
            value={marginTop}
            name='marginTop'
            // eslint-disable-next-line no-param-reassign
            onChange={onChange}
          />
          <Form.Input
            size='mini'
            label={`Margin bottom: ${marginBottom}px`}
            type='range'
            min={0}
            max={500}
            value={marginBottom}
            name='marginBottom'
            // eslint-disable-next-line no-param-reassign
            onChange={onChange}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export const GridRowDefaultProps = {
  background: '#ffffff',
  paddingLeft: '0',
  paddingRight: '0',
  paddingTop: '5',
  paddingBottom: '5',
  marginTop: '0',
  marginBottom: '0',
  border: '1',
  borderColor: '#000000',
  cellNumber: '4'
};

GridRow.defaultProps = GridRowDefaultProps;

GridRow.propTypes = {
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  borderColor: PropTypes.string,
  cellNumber: PropTypes.string,
  height: PropTypes.string
};

GridRow.craft = {
  props: GridRowDefaultProps,
  related: {
    settings: GridRowSettings
  },
  rules: {
    canMoveIn: node => {
      return node[0].data.name === 'GridCell';
    }
  }
};
