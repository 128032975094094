import { Checkbox, Input } from 'semantic-ui-react';

export const gridCellFields = [
  {
    name: 'cellWidth',
    control: Input,
    size: 'mini',
    label: 'cell_width',
    type: 'range',
    min: 10,
    max: 100,
    unit: '%',
    group: true,
    width: 8
  },
  {
    name: 'height',
    control: Input,
    size: 'mini',
    label: 'min_height',
    type: 'range',
    min: 10,
    max: 500,
    unit: 'px',
    group: true,
    width: 8
  },
  {
    name: 'paddingLeft',
    control: Input,
    size: 'mini',
    label: 'padding_left',
    type: 'range',
    min: 0,
    max: 500,
    unit: 'px',
    group: true,
    width: 8
  },
  {
    name: 'paddingRight',
    control: Input,
    size: 'mini',
    label: 'padding_right',
    type: 'range',
    min: 0,
    max: 500,
    unit: 'px',
    group: true,
    width: 8
  },
  {
    name: 'paddingTop',
    control: Input,
    size: 'mini',
    label: 'padding_top',
    type: 'range',
    min: 0,
    max: 500,
    unit: 'px',
    group: true,
    width: 8
  },
  {
    name: 'paddingBottom',
    control: Input,
    size: 'mini',
    label: 'padding_bottom',
    type: 'range',
    min: 0,
    max: 500,
    unit: 'px',
    group: true,
    width: 8
  },
  {
    name: 'borderWidth',
    control: Input,
    size: 'mini',
    label: 'border',
    type: 'range',
    min: 0,
    max: 10,
    unit: 'px',
    group: true,
    width: 8
  },

  {
    name: 'borderLeft',
    control: Checkbox,
    label: 'left',
    inline: true
  },
  {
    name: 'borderRight',
    control: Checkbox,
    label: 'right',
    inline: true
  },
  {
    name: 'borderTop',
    control: Checkbox,
    label: 'top',
    inline: true
  },
  {
    name: 'borderBottom',
    control: Checkbox,
    label: 'bottom',
    inline: true
  }
];

export const placeholderFields = [
  {
    name: 'height',
    control: Input,
    size: 'mini',
    label: 'min_height',
    type: 'range',
    min: 10,
    max: 500,
    unit: 'px',
    group: true,
    width: 16
  },
  {
    name: 'paddingLeft',
    control: Input,
    size: 'mini',
    label: 'padding_left',
    type: 'range',
    min: 0,
    max: 500,
    unit: 'px',
    group: true,
    width: 8
  },
  {
    name: 'paddingRight',
    control: Input,
    size: 'mini',
    label: 'padding_right',
    type: 'range',
    min: 0,
    max: 500,
    unit: 'px',
    group: true,
    width: 8
  },
  {
    name: 'paddingTop',
    control: Input,
    size: 'mini',
    label: 'padding_top',
    type: 'range',
    min: 0,
    max: 500,
    unit: 'px',
    group: true,
    width: 8
  },
  {
    name: 'paddingBottom',
    control: Input,
    size: 'mini',
    label: 'padding_bottom',
    type: 'range',
    min: 0,
    max: 500,
    unit: 'px',
    group: true,
    width: 8
  },
  {
    name: 'marginTop',
    control: Input,
    size: 'mini',
    label: 'margin_top',
    type: 'range',
    min: 0,
    max: 500,
    unit: 'px',
    group: true,
    width: 8
  },
  {
    name: 'marginBottom',
    control: Input,
    size: 'mini',
    label: 'margin_bottom',
    type: 'range',
    min: 0,
    max: 500,
    unit: 'px',
    group: true,
    width: 8
  }
];
