import React from 'react';
import PropTypes from 'prop-types';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import Label from 'semantic-ui-react/dist/commonjs/elements/Label/Label';
import List from 'semantic-ui-react/dist/commonjs/elements/List/List';
import { useTranslation } from 'react-i18next';
import {
  postByPathAndData,
  getByPathAndParams
} from '../../services/newServices';
import { TEMPLATES_PATHS } from '../../common/network/ApiPaths';
import styles from './Attachments.module.scss';

// ima ga u TabPanel komponenti - za sada nisam siguran gde se koristi
const Attachments = ({
  attachmentsData: { langAttachments, attachmentsAction }
}) => {
  const onChange = async event => {
    const file = event.target.files[0];
    const responseId = await postByPathAndData({
      data: file,
      type: file.type,
      path: TEMPLATES_PATHS.BIN_UPLOAD
    });
    if (responseId) {
      file.id = responseId;
      attachmentsAction({
        type: 'attach',
        payload: { file, language: langAttachments.language }
      });
    }
    document.querySelector('input[type="file"]').value = '';
  };
  const removeHandler = file => () =>
    attachmentsAction({
      type: 'detach',
      payload: { file, language: langAttachments.language }
    });

  const downloadHandler = file => async () => {
    const fileData = await getByPathAndParams({
      pathVariables: { id: file.id },
      path: TEMPLATES_PATHS.BIN_GET,
      responseType: 'blob'
    });
    if (fileData) {
      const url = window.URL.createObjectURL(new Blob([fileData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles.uploadFileWrap}>
        <Button primary>{t('upload_file')}</Button>
        <Label
          className={styles.uploadFileLabel}
          as='label'
          basic
          htmlFor='upload'
        >
          <input hidden id='upload' type='file' onChange={onChange} />
        </Label>
      </div>
      <div>test test test</div>
      <div className={styles.listWrap}>
        <List size='huge'>
          {langAttachments.binaries.map(file => (
            <List.Item key={file.name} className={styles.item}>
              <List.Icon
                name='file alternate outline'
                size='large'
                verticalAlign='middle'
                onClick={downloadHandler(file)}
              />
              <List.Content>
                <List.Header as='h4' onClick={downloadHandler(file)}>
                  {file.name}
                </List.Header>
              </List.Content>
              <Icon name='delete' size='small' onClick={removeHandler(file)} />
            </List.Item>
          ))}
        </List>
      </div>
    </div>
  );
};

Attachments.propTypes = {
  attachmentsData: PropTypes.shape({
    formType: PropTypes.string,
    langAttachments: PropTypes.shape({
      language: PropTypes.string,
      binaries: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          type: PropTypes.string,
          id: PropTypes.number
        })
      )
    }),
    attachmentsAction: PropTypes.func
  }).isRequired
};

export default Attachments;
