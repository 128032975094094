import { useReducer, useMemo } from 'react';
import { get, find, without } from 'lodash';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

function reducer(state, action) {
  const language = get(action, 'payload.language');
  const file = get(action, 'payload.file');
  const newState = [...state];
  switch (action.type) {
    case 'fetched':
      return action.payload.map(attachmentData => ({ ...attachmentData, attachIds: [], detachIds: [] }));
    case 'attach':
      if (!find(newState, ['language', language])) {
        newState.push({
          language,
          binaries: [file],
          attachIds: [file.id],
          detachIds: []
        });
      } else {
        state.forEach((attachData, index) => {
          if (attachData.language === language) {
            newState[index].attachIds.push(file.id);
            newState[index].binaries.push(file);
          }
        });
      }
      return newState;
    case 'detach':
      if (!find(newState, ['language', language])) {
        newState.push({
          language,
          binaries: [],
          attachIds: [],
          detachIds: [file.id]
        });
      } else {
        let indexOfLangAttachments = -1;
        state.forEach((attachData, index) => {
          if (attachData.language === language) {
            indexOfLangAttachments = index;
            if (newState[index].attachIds.includes(file.id)) {
              newState[index].attachIds = without(newState[index].attachIds, file.id);
            } else {
              newState[index].detachIds.push(file.id);
            }
          }
        });
        newState[indexOfLangAttachments].binaries = without(newState[indexOfLangAttachments].binaries, file);
      }
      return newState;
    case 'reset':
      return newState.map(attachData => ({ ...attachData, attachIds: [], detachIds: [] }));
    default:
      return state;
  }
}

function useAttachmentsData(fetchedData, activeLang) {
  const [allAttachments, dispatch] = useReducer(reducer, []);
  const langAttachments = useMemo(
    () => find(allAttachments, ['language', activeLang])
      || {
        language: activeLang,
        binaries: [],
        attachIds: [],
        detachIds: []
      },
    [allAttachments, activeLang]
  );
  useDeepCompareEffectNoCheck(() => {
    dispatch({ type: 'fetched', payload: fetchedData });
  }, [fetchedData]);

  return [allAttachments, langAttachments, dispatch];
}

export default useAttachmentsData;
