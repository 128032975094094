const API = {
  BASE_URL: process.env.REACT_APP_API_URL
};

const TEMPLATES_PATHS = Object.freeze({
  GET_ALL: '/templates',
  POST: '/templates',
  GET_BY_ID: '/templates/:id',
  DELETE_BY_ID: '/templates/:id',
  CLONE: '/templates/:id/:additionalPath',
  GET_FORM_TYPES: '/templates/formtype',
  PATCH_BY_ID: '/templates/:id',
  POST_DOCUMENT: '/documents',
  BIN_UPLOAD: '/templates/uploadBinary',
  BIN_GET: '/templates/getBinary/:id'
});

const EXTERNAL_APPLICATIONS = Object.freeze({
  GET_APPS: '/applications',
  SOURCES: '/sources'
});

export { API, TEMPLATES_PATHS, EXTERNAL_APPLICATIONS };
