import React from 'react';
import PropTypes from 'prop-types';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Select from 'semantic-ui-react/dist/commonjs/addons/Select/Select';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input/Input';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import TextArea from 'semantic-ui-react/dist/commonjs/addons/TextArea/TextArea';
import { useTranslation } from 'react-i18next';

const TemplateFormItem = ({
  panelState,
  item,
  onChangeHandler,
  value,
  error,
  itemLoading
}) => {
  const { t } = useTranslation();
  let formItem = null;

  if (Array.isArray(item)) {
    const items = item;
    formItem = (
      <Form.Group widths='equal'>
        {items.map((itm, index) => (
          <TemplateFormItem
            item={itm}
            key={itm.propName}
            onChangeHandler={onChangeHandler}
            value={value[index]}
            error={error[index]}
            panelState={panelState}
          />
        ))}
      </Form.Group>
    );
  } else if (
    (item.inEditMode && panelState === 'edit') ||
    (item.inCreateMode && panelState === 'create')
  ) {
    switch (item.inputType) {
      case 'disabled':
        formItem = (
          <Form.Field
            disabled
            value={value}
            control={Input}
            label={t(item.fieldNameKey)}
          />
        );

        break;
      case 'text':
        formItem = (
          <Form.Field
            required={item.required}
            error={error}
            value={value}
            name={item.propName}
            control={Input}
            label={t(item.fieldNameKey)}
            onChange={onChangeHandler}
          />
        );
        break;
      case 'select':
        formItem = (
          <Form.Field
            disabled={panelState === 'edit' && item.editDisabled}
            required={item.required}
            error={error}
            value={value}
            name={item.realName || item.propName}
            control={Select}
            label={t(item.fieldNameKey)}
            options={item?.options ?? []}
            onChange={onChangeHandler}
            loading={item.propName === itemLoading}
          />
        );
        break;
      case 'dropdown':
        formItem = (
          <Form.Field
            disabled={panelState === 'edit' && item.editDisabled}
            required={item.required}
            error={error}
            sourceOptions={item.sourceOptions}
            multiple
            selection
            search
            value={value}
            name={item.realName || item.propName}
            onChange={onChangeHandler}
            control={Dropdown}
            label={t(item.fieldNameKey)}
            options={item?.options ?? []}
          />
        );
        break;
      case 'textarea':
        formItem = (
          <Form.Field
            required={item.required}
            error={error}
            value={value || ''}
            name={item.propName}
            control={TextArea}
            label={t(item.fieldNameKey)}
            onChange={onChangeHandler}
          />
        );
        break;
      default:
        formItem = null;
    }
  }

  return formItem;
};

TemplateFormItem.defaultProps = {
  error: null,
  value: '',
  panelState: ''
};

TemplateFormItem.propTypes = {
  panelState: PropTypes.string,
  item: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object)
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))
  ]),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  onChangeHandler: PropTypes.func.isRequired
};

export default TemplateFormItem;
