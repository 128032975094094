import i18n from 'i18next';
import backend from 'i18next-http-backend';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import myEn from './locales/en.json';
import myDe from './locales/de.json';

i18n
  .use(backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: myEn },
      de: { translation: myDe }
    },
    backend: { loadPath: '/api/v1/getLanguage/{{lng}}' },
    fallbackLng: 'en',
    load: 'languageOnly',
    detection: {
      order: [
        'navigator',
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'htmlTag',
        'path',
        'subdomain'
      ]
    }
  });
